import axios from 'axios'

function getAxios() {
    return axios.create({
        baseURL: process.env.VUE_APP_API || '/api',
        timeout: 150000
    })
}
const axiosInstance = getAxios()
axiosInstance.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return error
    }
)
export { axiosInstance as axios }
