<template>
    <div class="footer">
        <div class="container clearfix">
            <div class="left">
                <div v-for="item in list" :key="item.company" class="item">
                    <span class="item-title">{{ item.company }}</span><br />
                    <span class="item-desc"> {{ item.desc }}</span>
                </div>
                <div class="item">
                    <span class="item-title">禹创·共创</span>
                </div>
            </div>
            <div class="center">
                <div class="item">
                    <a @click="$router.push({ name: 'survey' })">公司概况</a>
                    <a @click="$router.push({ name: 'media' })">媒体中心</a>
                    <span>电话：{{ detail.tel }}</span>
                </div>
                <div class="item">
                    <a @click="$router.push({ name: 'strategy' })">公司战略</a>
                    <a @click="$router.push({ name: 'download' })">资料下载</a>
                    <span>邮箱：{{ detail.mail }}</span>
                </div>
                <div class="item">
                    <a @click="$router.push({ name: 'product' })">产品服务</a>
                    <a @click="$router.push({ name: 'concact' })">联系我们</a>
                    <span
                        >地址：{{ detail.address }}</span
                    >
                </div>
                <div class="item">
                    <a @click="$router.push({ name: 'industry' })">行业覆盖</a>
                    <!-- <a href="http://www.yesbim.com/" target="_blank">拼模网</a> -->
                    <span style="margin-bottom: 0px; margin-left: 20%"
                        >微官网链接：<a
                            :href="detail.site"
                            target="_blank"
                            >{{ detail.site }}</a
                        ></span
                    >
                </div>
            </div>
            <div class="right">
                <img
                    :src="detail.code"
                    alt=""
                /><br />
                <span class="text">公众号</span>
            </div>
        </div>
        <div class="bottom">
            <a href="https://beian.miit.gov.cn" target="_blank"
                >沪ICP备15048788号-2</a
            >
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            detail: {}
        }
    },
    async created() {
        const data = await this.$store.dispatch(
            'getJson',
            this.$root.path['contact']
        )
        this.list = data.map(item => {
            return {
                img: item.img,
                company: item.company,
                desc: item.desc
            }
        })
        this.detail = data[0]
    },
}
</script>

<style lang="less" scoped>
.clearfix:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.footer {
    width: 100%;
    min-width: 1200px;
    height: 230px;
    padding: 28px 0 24px 0;
    background-color: #f4f4f4;
    .container {
        width: 900px;
        margin: 0 auto;
    }
    .left {
        float: left;
        width: 25%;
        .item {
            font-size: 12px;
            margin-bottom: 28px;
            line-height: 22px;
            .item-title {
                color: rgba(0, 0, 0, 1);
                // font-weight: 500;
                font-family: 'OPPOSM';
            }
            .item-desc {
                color: rgba(76, 76, 76, 1);
            }
        }
    }
    .right {
        float: right;
        width: 13%;
        text-align: right;
        img {
            width: 78px;
            height: 78px;
        }

        .text {
            text-align: center;
        }

        span {
            font-size: 12px;
            display: inline-block;
            width: 78px;
            margin-top: 10px;
            text-align: center;
        }
    }
    .center {
        float: left;
        width: 62%;

        .center-container {
            width: 90%;
            margin: 0 auto;
        }
        .item {
            width: 100%;
            // float: left;
            white-space: nowrap;

            > a {
                width: 20%;
            }

            a,
            span {
                font-size: 12px;
                line-height: 16px;
                display: inline-block;
                color: rgba(76, 76, 76, 1);
                margin-bottom: 15px;
            }
            a {
                &:hover {
                    text-decoration: underline;
                    color: #2486cb;
                }
            }
        }
    }
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        margin-top: 13px;
        a {
            color: #4c4c4c;
            &:hover {
                text-decoration: underline;
                color: #2486cb;
            }
        }
    }

	.bottom {
		text-align: center;
		font-size: 12px;
	}
}
</style>
