var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media"},[_c('blm-header'),_c('TopSwiper'),_c('div',{staticClass:"container"},[_c('blm-bread',{attrs:{"breadList":_vm.breadList}}),_c('div',{staticClass:"title btitle"},[_vm._v("平台优势")]),_c('div',{staticClass:"main clearfix"},[_c('div',{staticClass:"left clearfix"},[_c('div',{staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_vm._l((_vm.data.data),function(item,index){return _c('div',{key:index,class:{
                        btn: true,
                        [_vm.activeIndex === index ? 'active' : '']: true
                    },on:{"click":function($event){_vm.activeIndex = index}}},[_c('div',{staticClass:"ctitle"},[_vm._v(_vm._s(item.name))]),_c('div',{class:{
                            circle: true,
                            [_vm.activeIndex === index ? 'active' : '']: true
                        }})])})],2),_vm._l((_vm.data.data),function(item,index){return _c('div',{key:index,staticClass:"right"},[(index === _vm.activeIndex)?_c('div',[_c('img',{staticClass:"mainBackgroundImg",attrs:{"src":item.backgroundImg,"alt":""}}),(item.attractInvestment)?_c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.attractInvestment.title)+" ")]),_vm._l((item.attractInvestment
                                .lists),function(list,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(list)+" ")])})],2):_vm._e(),(item.ops)?_c('div',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.ops.title))]),_vm._l((item.ops.lists),function(list2,index){return _c('div',{key:index},[_c('div',{staticClass:"list2Name"},[_vm._v(_vm._s(list2.name))]),_c('div',{staticClass:"list2Content"},[_vm._v(" "+_vm._s(list2.content)+" ")])])})],2):_vm._e(),_c('div',{staticClass:"other"},_vm._l((item.lists),function(otherItem,index){return _c('div',{key:index,staticClass:"otherItem"},[_c('div',{staticClass:"otherItemTitle"},[_vm._v(" "+_vm._s(otherItem.name)+" ")]),_c('div',{staticClass:"otherItemContent"},[_vm._v(" "+_vm._s(otherItem.content)+" ")])])}),0)]):_vm._e()])})],2)],1),_c('blm-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }