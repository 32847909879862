<template>
    <div class="media">
        <blm-header></blm-header>
        <!-- <div class="banner">
      <img
        src="https://oss.cloud.custouch.com/res/99741/官网-媒体中心头图.jpg"
        alt=""
      />
      <p>
        BLM Digital <br />
        与您共创数字化世界
      </p>
    </div> -->
        <TopSwiper></TopSwiper>
        <div class="container">
            <blm-bread :breadList="breadList"></blm-bread>
            <div class="title btitle">平台优势</div>
            <div class="main clearfix">
                <div class="left clearfix">
                    <div class="slide"></div>
                    <div class="slide1"></div>
                    <div
                        :class="{
                            btn: true,
                            [activeIndex === index ? 'active' : '']: true
                        }"
                        v-for="(item, index) in data.data"
                        :key="index"
                        @click="activeIndex = index"
                    >
                        <div class="ctitle">{{ item.name }}</div>
                        <div
                            :class="{
                                circle: true,
                                [activeIndex === index ? 'active' : '']: true
                            }"
                        ></div>
                    </div>
                </div>

                <div
                    class="right"
                    v-for="(item, index) in data.data"
                    :key="index"
                >
                    <div v-if="index === activeIndex">
                        <img
                            class="mainBackgroundImg"
                            :src="item.backgroundImg"
                            alt=""
                        />
                        <!-- 这是产品价值独有的部分 -->
                        <div v-if="item.attractInvestment">
                            <div class="title">
                                {{ item.attractInvestment.title }}
                            </div>
                            <div
                                v-for="(list, index) in item.attractInvestment
                                    .lists"
                                :key="index"
                            >
                                {{ list }}
                            </div>
                        </div>
                        <div v-if="item.ops">
                            <div class="title">{{ item.ops.title }}</div>
                            <div
                                v-for="(list2, index) in item.ops.lists"
                                :key="index"
                            >
                                <div class="list2Name">{{ list2.name }}</div>
                                <div class="list2Content">
                                    {{ list2.content }}
                                </div>
                            </div>
                        </div>
                        <!-- 这是其他部分 -->
                        <div class="other">
                            <div
                                class="otherItem"
                                v-for="(otherItem, index) in item.lists"
                                :key="index"
                            >
                                <div class="otherItemTitle">
                                    {{ otherItem.name }}
                                </div>
                                <div class="otherItemContent">
                                    {{ otherItem.content }}
                                </div>
                            </div>
                        </div>
                        <!-- <div v-if="item.serviceContent && activeIndex === index">
              <p v-for="(value, k) in item.serviceContent" :key="k" v-html="value">
                <a v-if="item.serviceLink" :href="item.serviceLink">了解更多</a>
              </p>
            </div>
            <img v-if="item.serviceImg" :src="item.serviceImg" alt="" /> -->
                    </div>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
import getParentId from '@/service/recursion.js'
import TopSwiper from './components/TopSwiper.vue'
export default {
    data() {
        return {
            activeIndex: 0,
            data: {}
        }
    },
    components: {
        TopSwiper
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async getList() {
            let list = await this.$store.dispatch(
                'getJson',
                this.$root.path['产品和服务']
            )
            list.map(item => {
                if (item.name == '平台优势') {
                    this.data = item
                }
            })
            console.log(this.data)
        }
    }
}
</script>

<style lang="less" scoped>
.media {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 74%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }

    .slidebox {
        opacity: 0;
        position: absolute;
        width: 1006px;
        height: 208px;
        background-color: #f7f7f7;
        left: -55px;
        z-index: -1;
    }
    .slidebox1 {
        top: 570px;
    }
    .slidebox2 {
        top: 971px;
    }
    .slidebox3 {
        top: 169px;
    }
}
.main {
    font-family: OPPOSans-B;
    margin-top: 52px;
    .left {
        position: relative;
        border-right: 1px solid #d8d8d8;
        float: left;
        padding-right: 29px;
        z-index: 1;
        .slide,
        .slide1 {
            position: absolute;
            right: -1px;
            height: 14px;
            width: 1px;
            background-color: #fff;
            z-index: 999;
        }
        .slide {
            top: 0;
        }
        .slide1 {
            bottom: 0;
        }

        .btn {
            position: relative;
            width: 158px;
            height: 42px;
            margin: 0 auto;
            padding: 0 10px;
            color: #878787;
            margin-bottom: 12px;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .ctitle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;
            line-height: 20px;
            text-align: left;
        }
        .circle {
            position: absolute;
            top: 14px;
            right: -36px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #d8d8d8;
        }
        .active {
            color: #fff;
            background-color: #2486cb;
            font-family: 'OPPOSB';
            border: none;
        }
    }
    .right {
        margin: 0px 0 0 220px;
        // float: left;
        font-size: 16px;
        color: rgba(76, 76, 76, 1);
        line-height: 32px;
        .mainBackgroundImg {
            position: absolute;
            width: 900px;
            // height: 660;
            left: 0px;
            top: 150px;
        }
        img {
            width: 675px;
            margin-top: 17px;
        }
        .list2Name {
            width: 83px;
            height: 36px;
            background: #2486cb;
            border-radius: 2px;
            text-align: center;
            line-height: 36px;
            color: #ffffff;
        }
        .list2Content {
            margin: 14px 0;
        }
        .other {
            .otherItemTitle {
                display: inline-block;
                padding: 0 20px;
                height: 67px;
                background-image: url(https://oss.cloud.custouch.com/res/100257/编组.png);
                background-size: 100% 100%;
                text-align: center;
                color: white;
                line-height: 35px;
            }
            .otherItemContent {
                position: relative;
                top: -27px;
                left: 29px;
                padding: 13px 15px;
                width: 646px;
                background: #f6fafd;
                // background-color: red;
                border-radius: 2px;
            }
        }
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}
.container {
    padding-bottom: 166px;
    position: relative;
    width: 900px;
    margin: 0 auto;
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        font-family: 'OPPOSM';
        // font-weight: 500;
        color: #000;
    }
    .slide3 {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
}
</style>
