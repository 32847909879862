<template>
    <div class="download">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/19612/资料下载banner.png"
                alt=""
            />
            <p>
                BLM Digital <br />
                与您共创数字化世界
            </p>
        </div>
        <div class="contain">
            <div class="slide"></div>
            <div class="slide1"></div>
            <blm-bread :breadList="breadList"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide3" />
            </a-back-top>
            <div class="title" ref="download">资料下载</div>
            <div class="select">
                <div class="sort" @click="sortList">
                    {{ sort }}
                    <img
                        src="../assets/images/sort.png"
                        alt=""
                        :class="{ sortimg: isShow }"
                    />
                </div>
                <a-select
                    default-value="线下专题论坛"
                    style="width: 120px"
                    @change="handleChange"
                >
                    <a-select-option
                        :value="item"
                        v-for="(item, index) in type"
                        :key="index"
                    >
                        {{ item }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="detail clearfix">
                <div class="item1" v-for="(item, index) in list" :key="index">
                    <img v-if="item.banner" :src="item.banner" alt="" />
                    <p class="ctitle">{{ item.name }}</p>
                    <div class="down" v-if="item.name">
                        <img
                            class="read"
                            src="../assets/images/read.png"
                            alt=""
                        /><span
                            data-category="click"
                            data-action="link"
                            :data-label="item.name + '(' + item.link1 + ')'"
                            @click="jump($event, item, item.link1)"
                            >阅读</span
                        >
                        <img
                            class="load"
                            src="../assets/images/download.png"
                            alt=""
                        /><span
                            data-category="click"
                            data-action="download"
                            :data-label="item.name + '(' + item.link2 + ')'"
                            @click="jump($event, item, item.link2)"
                            >下载</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
import mixin from './mixins/mixin'
// import download from '../assets/data/download'
export default {
    mixins: [mixin],
    data() {
        return {
            download: [],
            // type: [],
            list: [],
            isShow: true,
            scroll: true
        }
    },
    async created() {
        await this.getData()
        await this.getList()
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)
        this.$nextTick(() => {
            anime({
                targets: '.slide', // 需要动画的元素
                opacity: [0, 1],
                translateX: [-300, 0],
                duration: 500, // 动画的时长
                easing: 'easeInSine' //运动曲线
            })
        })
    },
    methods: {
        async getData() {
            this.download = await this.$store.dispatch(
                'getJson',
                this.$root.path['资料下载']
            )
        },
        async getList() {
            await this.getData()
            this.handleChange(this.type[0])
        },
        handleChange(e) {
            this.list = this.download
                .filter(item => item.type === e)
                .sort((a, b) => {
                    return b.time - a.time
                })
        },
        sortList() {
            this.isShow = !this.isShow
            this.list.reverse()
        },
        jump(ev, item, link) {
            const title = this.$route.meta.title + '-' + item.type
            this.track(ev, title)
            window.open(link)
        }
    },

    computed: {
        breadList() {
            return this.$route.meta.breadList
        },
        sort() {
            if (this.isShow) {
                return '正序'
            } else {
                return '倒序'
            }
        },
        type() {
            return [...new Set(this.download.map(item => item.type))]
        }
    }
}
</script>

<style lang="less" scoped>
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.download {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 70%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }
    .slide {
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 172px;
        left: -51px;
        width: 1006px;
        height: 77px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .slide3 {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .intro {
        font-size: 16px;
        line-height: 32px;
        text-align: justify;
        color: rgba(76, 76, 76, 1);
        margin-top: 40px;
    }
    .contain {
        position: relative;
        width: 900px;
        margin: 0 auto;
    }
}
.detail {
    padding: 57px 0 22px 0;
    display: flex;
    flex-wrap: wrap;

    .item1 {
        margin-bottom: 66px;
        /* float: left; */
        margin-right: 84px;
        width: 244px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-child(3n) {
            margin-right: 0;
        }
        .ctitle {
            flex: 1;
            margin: 16px 0 16px 0;
            font-size: 20px;
            line-height: 32px;
            color: #000;
        }
        img {
            width: 100%;
            box-shadow: 0 2px 4px 0 rgba(222, 228, 232, 1);
        }
        .down {
            font-size: 16px;
            line-height: 20px;
            color: rgba(76, 76, 76, 1);
            span {
                color: #4c4c4c;
                &:hover {
                    color: #2486cb;
                }
            }
        }
        .read {
            width: 24px;
            margin-right: 11px;
            box-shadow: none;
        }
        .load {
            width: 26px;
            margin-left: 32px;
            margin-right: 11px;
            box-shadow: none;
        }
    }
}
.select {
    height: 36px;
    margin-top: 127px;
    .sort {
        float: right;
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
        margin-left: 48px;
        cursor: pointer;
        img {
            // transition: all .5s;
            margin-left: 10px;
            width: 21px;
            vertical-align: middle;
            margin-bottom: 4px;
            transform: rotate(0deg);
            &.sortimg {
                transform: rotate(-180deg);
            }
        }
    }
}
/deep/ .ant-select {
    width: 180px !important;
    float: right !important;
    height: 36px !important;
}
/deep/ .ant-select-selection,
/deep/ .ant-select-selection__rendered {
    border-radius: 0 !important;
    height: 36px;
}
/deep/ .ant-select-selection-selected-value {
    height: 36px;
    line-height: 36px;
}
/deep/ .ant-select-focused .ant-select-selection,
/deep/ .ant-select-selection:focus,
/deep/ .ant-select-selection:active {
    border-color: #d8d8d8;
    box-shadow: none;
}
/deep/ .ant-select-selection:hover {
    border-color: #d8d8d8;
    box-shadow: none;
}
/deep/ .ant-select-arrow {
    color: #2486cb;
}
</style>
