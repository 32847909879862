<template>
    <div class="concept">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/19608/产品和服务banner.png"
                alt=""
            />
            <p>BLM Digital <br />与您共创数字化世界</p>
        </div>
        <div class="container">
            <blm-bread :breadList="breadList"></blm-bread>
            <div class="slide"></div>
            <div class="title">什么是BLM</div>
            <div class="intro">
                BLM是Building Lifecycle
                Management建筑全生命周期管理的简称，建筑全生命周期管理通常分为规划、设计、施工、运维几个阶段。<br />
                BLM是公司独创的建筑数字化理念，将BIM模型贯穿建筑全生命周期，在提供各类应用的同时，积累从规划阶段到运营阶段的所有的几何和非几何信息，让“数据”成为建筑智慧化的基石。
            </div>
            <div class="content" v-for="(item, index) in list" :key="index">
                <div class="ctitle">
                    {{ item.title }}
                    <div class="slide1"></div>
                </div>
                <p class="detail">{{ item.content }}</p>
            </div>
            <div class="intro">
                我们将BIM提升为BLM，通过BLM建筑全生命周期管理，为业主实现建筑的健康和资产的保值增值。
            </div>
            <img
                class="image"
                style="width: 900px; margin-top: 80px"
                src="https://oss.cloud.custouch.com/res/19735/官网-什么是BLM-内容配图.png"
                alt=""
            />
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
export default {
    data() {
        return {
            list: [
                {
                    title: '应用领域',
                    content:
                        'BIM在建筑全生命周期管理的各阶段都有应用，特别是在运维阶段的应用占比很大，因为运维阶段在建筑的寿命中（全生命周期管理过程中）超过了40年。'
                },
                {
                    title: '主要内容',
                    content:
                        'BIM主要包含几何信息与非几何信息两大部分；几何信息中，包含了建筑物的各项参数，我们BIM建模的细度也是从左往右，越来越精细；非几何信息包括了规划指标、设计参数、材料信息、维保信息等，最终BIM全过程的信息构成了建筑大数据。'
                },
                {
                    title: '应用场景',
                    content:
                        'BIM在不同阶段的应用场景也非常丰富。包含有方案比选、管线综合、空间管理等众多场景。'
                },
                {
                    title: '动态交付',
                    content:
                        'BIM竣工模型交付是一个动态交付的过程，不仅涵盖了建设阶段的信息，还包括了运维阶段的信息。竣工模型的轻量化导入是后期运维阶段的数字化管理的基础。'
                }
            ]
        }
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)
        window.addEventListener('load', this.load)
        this.$nextTick(() => {
            anime({
                targets: '.slide', // 需要动画的元素
                opacity: [0, 1],
                translateX: [-200, 0],
                duration: 1000, // 动画的时长
                easing: 'easeInSine' //运动曲线
            })
        })
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
        window.removeEventListener('load', this.load)
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList
        }
    }
}
</script>

<style lang="less" scoped>
.concept {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
        }

        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 20%;
            z-index: 999;
            line-height: 68px;
            font-family: 'OPPOSB';
            font-size: 38px;
            color: #fff;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.container {
    padding-bottom: 166px;
    position: relative;
    width: 900px;
    margin: 0 auto;

    .slide {
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }

    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }

    .intro {
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
        margin-top: 40px;
    }
}

.content {
    padding-top: 36px;

    .ctitle {
        position: relative;
        padding-left: 15px;
        width: 55px;
        height: 64px;
        line-height: 64px;
        // font-weight: 500;
        font-family: 'OPPOSM';
        font-size: 20px;
        color: #000;
        white-space: nowrap;
        background-color: rgba(145, 205, 240, 0.9);
    }

    .slide1 {
        position: absolute;
        width: 80px;
        height: 63px;
        top: 13px;
        left: 36px;
        z-index: -1;
        background-color: rgba(145, 205, 240, 0.5);
    }

    .detail {
        padding-left: 15px;
        margin-top: 28px;
        font-size: 16px;
        line-height: 32px;
        text-align: justify;
        color: rgba(76, 76, 76, 1);
    }
}
</style>
