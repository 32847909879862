<template>
    <div class="industry">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/99742/官网-行业覆盖头图.jpg"
                alt=""
            />
            <p>
                BLM Digital <br />
                与您共创数字化世界
            </p>
        </div>
        <div class="container">
            <blm-bread :breadList="breadList"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide3" />
            </a-back-top>
            <div class="title btitle" ref="industry">行业覆盖</div>
            <p class="intro">
                作为建筑信息行业发展的开拓者和实践者，在园区、商办、住宅、学校、养老院、工业、市政、医院等多个建设领域中，深入探索了BIM技术在工程全生命周期中的应用，其中包括为临港集团、申迪集团、地产集团、华谊集团、陆家嘴集团、金桥集团、张江集团、浦发集团、港城集团、浦开集团、紫江集团等大型地产商提供全过程BIM咨询服务和数字化运维管理平台。
            </p>
            <div class="slide"></div>
            <div class="slide1"></div>
            <div class="main clearfix">
                <div
                    class="item"
                    ref="item"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="goDetail(item[1])"
                >
                    <div class="img">
                        <img :src="item[0]" alt="" class="img1" />
                    </div>
                    <p>{{ item[1] }}</p>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
// import industry from '../assets/data/industry'
export default {
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        }
    },
    data() {
        return {
            list: {},
            scroll: true,
            inScroll: true
            // industry: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)
        this.$nextTick(() => {
            this.animotion()
        })
    },
    methods: {
        async getIndustry() {
            const industry = await this.$store.dispatch(
                'getJson',
                this.$root.path['行业覆盖']
            )
            let m = new Map()
            industry.forEach(item => {
                m.set(item.tradeImg, item.level1)
            })
            this.list = m
        },
        goDetail(i) {
            this.$router.push({
                name: 'industryList',
                query: {
                    title: i
                }
            })
        },
        detectScroll() {
            this.animotion()
        },
        animotion() {
            const industry = this.$refs.industry.getBoundingClientRect().top
            const item1 =
                this.$refs.item &&
                this.$refs.item[0].getBoundingClientRect().top
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            if (industry < clientH && this.inScroll) {
                this.inScroll = false
                anime({
                    targets: '.slide', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                anime({
                    targets: '.slide1', // 需要动画的元素
                    opacity: [0, 1],
                    translateY: [300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                anime({
                    targets: '.btitle, .intro', // 需要动画的元素
                    opacity: [0, 1],
                    translateY: [300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(100, { start: 350 })
                })
            }
            if (item1 < clientH && this.scroll) {
                this.scroll = false
                anime({
                    targets: '.main .item', // 需要动画的元素
                    opacity: [0, 1],
                    translateY: [300, 0],
                    duration: 1000, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 100 })
                })
            }
        }
    },
    created() {
        this.getIndustry()
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    }
}
</script>

<style lang="less" scoped>
.industry {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 58%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.container {
    position: relative;
    width: 900px;
    margin: 0 auto;
    padding-bottom: 16px;
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .intro {
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
        margin-top: 40px;
    }
    .slide {
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 170px;
        left: -51px;
        width: 1006px;
        height: 144px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .slide3 {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
}
.main {
    margin-top: 120px;
    .item {
        float: left;
        width: 406px;
        height: 320px;
        box-shadow: 0 2px 4px 0 #dee4e8;
        margin-bottom: 80px;
        cursor: pointer;
        &:nth-child(2n) {
            margin-left: 87px;
        }
        .img {
            overflow: hidden;
        }
        .img1 {
            transition: all 1s ease;
            width: 100%;
            &:hover {
                transform: scale(2);
            }
        }
        p {
            height: 74px;
            line-height: 74px;
            text-align: center;
            font-size: 20px;
            color: #000;
        }
    }
}
</style>
