export default {
    data() {
        return {
            UAID : 'UA-'+process.env.VUE_APP_SITE_ID
        }
    },
    methods: {
        track(ev, title) {
            window.ca('send', 'event', {
                eventCategory: ev.target.dataset['category'],
                eventAction: ev.target.dataset['action'],
	            eventLabel: ev.target.dataset['label'],
                title
            })
        },
        pageTrack(title) {
            let page = {
                title,//页面标题
                path: window.location.href,
            }
            window.ca('send', 'pageview', page)
        }
    }
}
