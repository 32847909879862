import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Survey from '../views/Survey'
import Strategy from '../views/Strategy'
import Media from '../views/Media'
import MediaType from '../views/MediaType'
import Industry from '../views/Industry'
import IndustryList from '../views/IndustryList'
import Product from '../views/Product'
import ServiceList from '../views/ServiceList'
import ProductList from '../views/ProductList'
import Concept from '../views/Concept'
import Download from '../views/Download'
import Concact from '../views/Concact'
import Search from '../views/Search'
// import managePlatform from '../views/BlmManage/managePlatform'
import managePlatform from '../views/managePlatform'
import majorFunction from '@/views/majorFunction.vue'
import Advantage from '@/views/Advantage.vue'
import FunctionDetail from '@/views/FunctionDetail.vue'
import CaseList from '@/views/CaseList.vue'
import CaseDetail from '@/views/CaseDetail.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/majorFunction',
    name: 'majorFunction',
    component: majorFunction,
    meta: {
      title: '主要功能',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        },
        {
          name: '数字化建筑资产管理平台',
          path: '/managePlatform'
        },
        {
          name: '主要功能',
          path: '/majorFunction'
        }
      ]
    }
  },
  {
    path: '/FunctionDetail',
    name: 'FunctionDetail',
    component: FunctionDetail,
    meta: {
      title: '数字化建筑资产管理平台',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        },
        {
          name: '数字化建筑资产管理平台',
          path: '/managePlatform'
        },
        {
          name: '主要功能',
          path: '/majorFunction'
        }
      ]
    }
  },
  {
    path: '/CaseList',
    name: 'CaseList',
    component: CaseList,
    meta: {
      title: '成功案例',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        },
        {
          name: '数字化建筑资产管理平台',
          path: '/managePlatform'
        },
        {
          name: '成功案例',
          path: '/CaseList'
        }
      ]
    }
  },
  {
    path: '/CaseDetail',
    name: 'CaseDetail',
    component: CaseDetail,
    meta: {
      title: '',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        },
        {
          name: '数字化建筑资产管理平台',
          path: '/managePlatform'
        },
        {
          name: '成功案例',
          path: '/CaseList'
        }
      ]
    }
  },

  {
    path: '/managePlatform',
    name: 'managePlatform',
    component: managePlatform,
    meta: {
      title: '数字化建筑资产管理平台',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        },
        {
          name: '数字化建筑资产管理平台',
          path: '/managePlatform'
        }
      ]
    }
  },
  {
    path: '/Advantage',
    name: 'Advantage',
    component: Advantage,
    meta: {
      title: '数字化建筑资产管理平台',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        },
        {
          name: '数字化建筑资产管理平台',
          path: '/managePlatform'
        }, {
          name: "平台优势",
          path: "/Advantage"
        }
      ]
    }
  },
  {
    path: '/survey',
    name: 'survey',
    component: Survey,
    meta: {
      title: '公司概况',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '公司概况',
          path: '/survey'
        },
      ]
    }
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: Strategy,
    meta: {
      title: '公司战略',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '公司战略',
          path: '/strategy'
        },
      ]
    }
  },
  {
    path: '/media',
    name: 'media',
    component: Media,
    meta: {
      title: '媒体中心',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '媒体中心',
          path: '/media'
        },
      ]
    }
  },
  {
    path: '/media/category',
    name: 'mediatype',
    component: MediaType,
    meta: {
      title: '媒体中心',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '媒体中心',
          path: '/media'
        },
      ]
    }
  },
  {
    path: '/industry',
    name: 'industry',
    component: Industry,
    meta: {
      title: '行业覆盖',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '行业覆盖',
          path: '/industry'
        }
      ]
    }
  },
  {
    path: '/industry/detail',
    name: 'industryList',
    component: IndustryList,
    meta: {
      title: '行业覆盖',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '行业覆盖',
          path: '/industry'
        }
      ]
    }
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
    meta: {
      title: '产品和服务',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        }
      ]
    }
  },
  {
    path: '/service/list',
    name: 'serviceList',
    component: ServiceList,
    meta: {
      title: '产品和服务-BLM服务',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        }
      ]
    }
  },
  {
    path: '/product/list',
    name: 'productList',
    component: ProductList,
    meta: {
      title: '产品和服务-BLM产品',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '产品和服务',
          path: '/product'
        }
      ]
    }
  },
  {
    path: '/concept',
    name: 'concept',
    component: Concept,
    meta: {
      title: '什么是BLM',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '什么是BLM',
          path: '/concept'
        }
      ]
    }
  },
  {
    path: '/download',
    name: 'download',
    component: Download,
    meta: {
      title: '资料下载',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '资料下载',
          path: '/download'
        }
      ]
    }
  },
  {
    path: '/concact',
    name: 'concact',
    component: Concact,
    meta: {
      title: '联系我们',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '联系我们',
          path: '/concact'
        }
      ]
    }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      title: '搜索',
      breadList: [
        {
          name: 'BLM主页',
          path: '/'
        },
        {
          name: '搜索',
          path: '/search'
        }
      ]
    }
  },
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  if (to.name === 'mediatype' || to.name === 'industryList' || to.name === 'serviceList' || to.name === 'productList') {
    return
  } else {
    // console.log(to.fullPath);
    let page = {
      title: to.meta.title,
      path: window.location.href,
    }
    window.ca('send', 'pageview', page)
  }
})

export default router
