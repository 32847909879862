<template>
    <div class="media">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/100243/位图备份_2.png"
                alt=""
            />
        </div>
        <div class="container">
            <div class="slidebox slidebox1"></div>
            <div class="slidebox slidebox2"></div>
            <div class="slidebox slidebox3"></div>
            <blm-bread :breadList="breadList"></blm-bread>
            <div ref="slide" class="slide"></div>
            <div class="slide1"></div>
            <div class="title btitle">数字化建筑资产管理平台</div>
            <p class="intro pintro">
                数字化建筑资产管理平台提供空间资产运营、楼宇设施管理、智能化集成管理于一体的“产品+服务”模式，实现建筑资产的闭环管理。
            </p>
            <div
                :class="[
                    'type',
                    'clearfix',
                    { type1: index === 0 },
                    { type2: index === 1 },
                    { type3: index === 2 }
                ]"
                v-for="(item, index) in productData"
                :key="index"
            >
                <div class="img" ref="type">
                    <img :src="item.productBanner" alt="" class="img1" />
                </div>
                <div class="right">
                    <div class="title">{{ item.name }}</div>
                    <!-- <div class="subtitle intro">{{item.subtitle}}</div> -->
                    <div class="blueRectangle subtitle"></div>
                    <a
                        class="more"
                        @mouseover="item.hidemore = true"
                        @mouseout="item.hidemore = false"
                        @click="jump(item.name)"
                        >了解更多
                        <img
                            src="../assets/images/arrow.png"
                            alt=""
                            :class="{ show: item.hidemore }"
                        />
                    </a>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
export default {
    data() {
        return {
            list: [
                {
                    title: '专题论坛',
                    hidemore: false,
                    subtitle: '阅读精彩论坛内容',
                    url:
                        'https://oss.cloud.custouch.com/res/18973/官网-媒体中心-专题论坛966x636.jpg'
                },
                {
                    title: '视频中心',
                    hidemore: false,
                    subtitle: '观看最新发布视频',
                    url:
                        'https://oss.cloud.custouch.com/res/18977/官网-媒体中心-视频中心966x636.jpg'
                },
                {
                    title: '新闻报道',
                    hidemore: false,
                    subtitle: '获取企业新闻资讯',
                    url:
                        'https://oss.cloud.custouch.com/res/18990/官网-媒体中心-新闻报道966x636.jpg'
                }
            ],
            // type1: 0,
            showSlide: true,
            showType1: true,
            showType2: true,
            showType3: true,
            productData: []
        }
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)

        this.getData()
    },
    methods: {
        async getData() {
            let list = await this.$store.dispatch(
                'getJson',
                this.$root.path['产品和服务']
            )
            this.productData = list.filter(
                item => item.level2 == '数字化建筑资产管理平台'
            )
            console.log(this.productData)
            this.$nextTick(() => {
                this.animotion()
            })
        },

        jump(i) {
            if (i == '主要功能') {
                this.$router.push({ name: 'majorFunction' })
            } else if (i == '平台优势') {
                this.$router.push({ name: 'Advantage' })
            } else if (i == '成功案例') {
                this.$router.push({ name: 'CaseList' })
            }
            // this.$router.push({
            //     name: 'mediatype',
            //     query: {
            //           title: i
            //     }
            // })
        },
        detectScroll() {
            this.animotion()
        },
        animotion() {
            const slide = this.$refs.slide.getBoundingClientRect().top
            const type1 = this.$refs.type[0].getBoundingClientRect().top
            const type2 = this.$refs.type[1].getBoundingClientRect().top
            const type3 = this.$refs.type[2].getBoundingClientRect().top
            const target1 = [
                '.type1 .img',
                '.type1 .title',
                '.slidebox3',
                '.type1 .subtitle',
                '.type1 .more'
            ]
            const target2 = [
                '.type2 .img',
                '.type2 .title',
                '.slidebox1',
                '.type2 .subtitle',
                '.type2 .more'
            ]
            const target3 = [
                '.type3 .img',
                '.type3 .title',
                '.slidebox2',
                '.type3 .subtitle',
                '.type3 .more'
            ]
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            if (slide < clientH && this.showSlide) {
                this.showSlide = false
                anime({
                    targets: '.slide', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                anime({
                    targets: '.slide1, .btitle, .pintro', // 需要动画的元素
                    opacity: [0, 1],
                    translateY: [300, 0],
                    duration: 700, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 10 })
                })
            }
            if (type1 < clientH && this.showType1) {
                this.showType1 = false
                this.animeted(target1)
            }
            if (type2 < clientH && this.showType2) {
                this.showType2 = false
                this.animeted(target2)
            }
            if (type3 < clientH && this.showType3) {
                this.showType3 = false
                this.animeted(target3)
            }
        },
        animeted(targets) {
            anime({
                targets, // 需要动画的元素
                opacity: [0, 1],
                translateY: [300, 0],
                duration: 700, // 动画的时长
                easing: 'easeInSine', //运动曲线
                delay: anime.stagger(200, { start: 200 })
            })
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    }
}
</script>

<style lang="less" scoped>
.media {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            max-height: 630px;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 74%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }

    .slidebox {
        opacity: 0;
        position: absolute;
        width: 455px;
        height: 208px;
        background-color: #f7f7f7;
        left: 406px;
        z-index: -1;
    }
    .slidebox1 {
        top: 820px;
    }
    .slidebox2 {
        top: 1235px;
    }
    .slidebox3 {
        top: 403px;
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.container {
    position: relative;
    width: 900px;
    margin: 0 auto;
    .slide {
        opacity: 0;
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 170px;
        left: -51px;
        width: 1006px;
        height: 110px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .title {
        opacity: 0;
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .intro {
        opacity: 0;
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
    }
    .more {
        opacity: 0;
        display: inline-block;
        font-size: 16px;
        line-height: 21px;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #2486cb;
        margin-top: 24px;
        img {
            transition: all 0.8s ease;
            width: 22px;
            opacity: 0;
            margin-left: 10px;
            transform: translateX(-10px);
            &.show {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
    p {
        margin-top: 40px;
        margin-bottom: 24px;
    }
    .type {
        margin-top: 96px;
        &:last-child {
            margin-bottom: 96px;
        }
        .right {
            float: left;
            padding-top: 88px;
            margin-left: 32px;
        }
        .img {
            opacity: 0;
            float: left;
            width: 483px;
            height: 318px;
            overflow: hidden;
        }
        .img1 {
            width: 100%;
            height: 100%;
            transition: all 1s ease;
            &:hover {
                transform: scale(2);
            }
        }
    }
}
.blueRectangle {
    width: 64px;
    height: 12px;
    background: #91cdf0;
}
</style>
