<template>
    <div class="mediatype">
        <blm-header></blm-header>
        <div class="banner">
            <img :src="banner" alt="" />
        </div>
        <div class="container">
            <blm-bread :breadList="breadList" :query="query"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide" />
            </a-back-top>
            <div class="title">{{ query }}</div>
            <!-- <div v-if="desc[0].banner"> -->
            <div>
                <div class="select">
                    <div class="sort" @click="sortList">
                        {{ sort }}
                        <img
                            src="../assets/images/sort.png"
                            alt=""
                            :class="{ sortimg: isShow }"
                        />
                    </div>
                    <a-select
                        :default-value="type[0]"
                        style="width: 120px"
                        @change="handleChange"
                        v-if="show"
                    >
                        <a-select-option
                            v-for="(item, index) in type"
                            :key="index"
                            :value="item"
                        >
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </div>
                <!-- <div class="content" v-if="desc[0].banner"> -->
                <div
                    class="desc clearfix"
                    v-for="(item, index) in desc"
                    :key="index"
                >
                    <img :src="item.banner" alt="" />
                    <div class="word">
                        <div class="title">{{ item.name }}</div>
                        <div class="detail">{{ item.desc }}</div>
                        <a
                            class="more"
                            data-category="click"
                            data-action="了解更多"
                            :data-label="item.name + '(' + item.link + ')'"
                            @click="clickFn($event, item, item.link)"
                            >了解更多</a
                        >
                        <a
                            class="more look"
                            v-if="item.link1"
                            data-category="click"
                            :data-action="item.link1[0]"
                            :data-label="item.name + '(' + item.link1[1] + ')'"
                            @click="clickFn($event, item, item.link1[1])"
                            >{{ item.link1[0] }}</a
                        >
                        <a
                            class="more look"
                            v-if="item.link2"
                            data-category="click"
                            :data-action="item.link2[0]"
                            :data-label="item.name + '(' + item.link2[1] + ')'"
                            @click="clickFn($event, item, item.link2[1])"
                            >{{ item.link2[0] }}</a
                        >
                    </div>
                </div>
                <!-- </div> -->
            </div>
            <!-- <div class="main" v-else>敬请期待</div> -->
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
// import media from '../assets/data/media'
import mixin from './mixins/mixin'
export default {
    mixins: [mixin],
    data() {
        return {
            query: this.$route.query.title,
            list: {},
            type: [],
            desc: [],
            isShow: true,
            media: []
        }
    },
    async created() {
        const title = `${this.$route.meta.title}-${this.$route.query.title}`
        this.pageTrack(title)
        await this.getList()
        this.desc = this.list.get(this.type[0]).sort((a, b) => b.time - a.time)
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        },
        sort() {
            if (this.isShow) {
                return '正序'
            } else {
                return '倒序'
            }
        },
        show() {
            if (!this.type.length) return
            return this.type[0].indexOf('/') === -1
        },
        banner() {
            if (!this.media.length) return
            return this.media.find(
                item => item.level1 === this.$route.query.title
            ).topbanner
        }
    },
    methods: {
        handleChange(e) {
            this.desc = this.list.get(e)
        },
        async getList() {
            this.media = await this.$store.dispatch(
                'getJson',
                this.$root.path['媒体中心']
            )
            const arr = this.media.filter(item => item.level1 === this.query)
            arr.forEach(item => {
                if (item.level1 === '新闻报道') {
                    item.link1 = item.link1 && item.link1.split(' ')
                    item.link2 = item.link2 && item.link2.split(' ')
                }
            })
            this.type = [...new Set(arr.map(item => item.type))]
            let m = new Map()
            arr.forEach((item, index) => {
                m.set(
                    item.type,
                    arr.filter(i => i.type === item.type)
                )
            })
            this.list = m
        },
        clickFn(ev, item, link) {
            const title = `${this.$route.meta.title}-${item.level1}-${item.type}`
            // console.log(ev.target);
            this.track(ev, title)
            window.open(link)
        },
        sortList() {
            this.isShow = !this.isShow
            this.desc.reverse()
        }
    }
}
</script>

<style lang="less" scoped>
.mediatype {
    .banner {
        img {
            width: 100%;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.main {
    text-align: center;
    font-size: 20px;
    height: 100px;
}
.container {
    padding-bottom: 140px;
    position: relative;
    width: 900px;
    margin: 0 auto;
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .slide {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
}
.desc {
    margin-top: 55px;
    img {
        float: left;
        margin-top: 17px;
        margin-right: 25px;
        width: 400px;
        height: 170px;
    }
    .word {
        float: left;
        width: 463px;
    }
    .title {
        font-size: 20px;
        line-height: 40px;
        color: #000;
        // font-weight: 500;
        font-family: 'OPPOSM';
        height: 81px;
    }
    .detail {
        margin-top: 4px;
        font-size: 16px;
        line-height: 28px;
        color: #4c4c4c;
        height: 81px;
    }
    .more {
        margin-top: 11px;
        // font-weight: 500;
        font-family: 'OPPOSM';
        font-size: 16px;
        line-height: 16pxpx;
        color: #2486cb;
        &:hover {
            text-decoration: underline;
        }
    }
    .look {
        float: right;
        margin: 0;
        margin-left: 20px;
        background-color: rgba(36, 134, 203, 0.2);
    }
}
.select {
    height: 36px;
    margin-top: 12px;
    .sort {
        float: right;
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
        margin-left: 48px;
        cursor: pointer;
        img {
            // transition: all .5s;
            margin-left: 10px;
            width: 21px;
            vertical-align: middle;
            margin-bottom: 4px;
            // transform: rotate(0deg);
            &.sortimg {
                transform: rotate(-180deg);
            }
        }
    }
}
/deep/ .ant-select {
    width: 180px !important;
    float: right !important;
    height: 36px !important;
}
/deep/ .ant-select-selection,
/deep/ .ant-select-selection__rendered {
    border-radius: 0 !important;
    height: 36px;
}
/deep/ .ant-select-selection-selected-value {
    height: 36px;
    line-height: 36px;
}
/deep/ .ant-select-focused .ant-select-selection,
/deep/ .ant-select-selection:focus,
/deep/ .ant-select-selection:active {
    border-color: #d8d8d8;
    box-shadow: none;
}
/deep/ .ant-select-selection:hover {
    border-color: #d8d8d8;
    box-shadow: none;
}
/deep/ .ant-select-arrow {
    color: #2486cb;
}
</style>
