<template>
    <div class="productlist">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/19629/官网-产品和服务-BLM产品banner2880x954.jpg"
                alt=""
            />
        </div>
        <div class="container">
            <blm-bread :breadList="breadList" :query="query"></blm-bread>
            <a-back-top :visibilityHeight="1100">
                <img src="../assets/images/top.png" alt="" class="slide" />
            </a-back-top>
            <div class="title">{{ query }}</div>
            <div class="content">
                <div class="detail" v-if="list.serviceContent">
                    {{ list.serviceContent }}
                </div>
                <img
                    v-for="(item, index) in list.serviceImg"
                    :key="index"
                    :src="item"
                    alt=""
                />
                <img :src="list.serviceImg1" alt="" />
                <img :src="list.serviceImg2" alt="" />
                <!-- <img v-if="list.url2" :src="list.url2" alt=""> -->
                <div class="desc" v-if="list.serviceContent2">
                    <div class="ctitle">产品主要内容:</div>
                    <ul>
                        <li
                            class="detail"
                            v-for="(item, index) in list.serviceContent2"
                            :key="index"
                        >
                            {{ item }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="btn" v-if="list.serviceLink">
                <!-- <div class="button" @click="goLink(list.serviceLink)">立即体验</div> -->
                <div class="contactUs">
                    <div class="imgAndUs" @click="goContact">
                        <img
                            src="https://oss.cloud.custouch.com/res/100940/2de704ef-e6c4-4482-b0fd-13d51e2b5b59.png"
                            alt=""
                        />
                        <p style="font-weight: 550">欢迎联系我们,立即来体验</p>
                    </div>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import mixin from './mixins/mixin'
export default {
    mixins: [mixin],
    data() {
        return {
            list: {}
        }
    },
    created() {
        const title = `${this.$route.meta.title}-${this.$route.query.title}`
        this.pageTrack(title)
    },
    methods: {
        async getList() {
            this.list = (
                await this.$store.dispatch(
                    'getJson',
                    this.$root.path['产品和服务']
                )
            ).find(item => item.name === this.$route.query.title)
            console.log(this.list)
            if (this.list.serviceContent2) {
                this.list.serviceContent2 = this.list.serviceContent2.split(';')
            }
            if (this.list.serviceImg) {
                this.list.serviceImg = this.list.serviceImg.split(';')
            }
        },
        goLink(l) {
            window.open(l)
        },
        goContact() {
            window.location.href =
                'https://form.cloud.custouch.com/show/991949376479997952'
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getList()
        })
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        },
        query() {
            return this.$route.query.title
        }
    }
}
</script>

<style lang="less" scoped>
.productlist {
    .banner {
        img {
            width: 100%;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.container {
    padding-bottom: 112px;
    position: relative;
    width: 900px;
    margin: 0 auto;
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .slide {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
    .btn {
        width: 100%;
        margin-top: 113px;
        .button {
            cursor: pointer;
            margin: 0 auto;
            width: 153px;
            height: 46px;
            font-size: 20px;
            font-family: 'OPPOSM';
            // font-weight: 500;
            line-height: 46px;
            color: #2486cb;
            text-align: center;
            box-shadow: 0 4px 4px 0 #d0d0d0;
        }
    }
}
.content {
    text-align: center;
    .detail {
        text-align: left;
        padding-top: 36px;
        padding-bottom: 30px;
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
    }
    img {
        width: 728px;
        margin-top: 82px;
        &:last-child {
            margin-top: 148px;
        }
    }
}
.desc {
    margin-top: 80px;
    .ctitle {
        text-align: left;
        font-size: 18px;
    }
    ul {
        padding-left: 25px;
        margin-top: 20px;
    }
    li.detail {
        padding: 0;
        // line-height: 32px;
    }
}
.contactUs {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .imgAndUs {
        text-align: center;
    }

    img {
        width: 86px;
    }

    p {
        margin-top: 10px;
        text-align: center;
        font-family: OPPOSans-B;
        font-weight: B;
        font-size: 16px;
        color: #197fc1;
        letter-spacing: 0;
        text-align: justify;
        line-height: 32px;
    }
}
</style>
