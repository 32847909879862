// const getParentId(list, iid){
//     for(let o of list || []) {
//         if(o.title == iid) return o
//         const o_ = this.getParentId(o.data, iid)
//         if(o_) return o_
//     }
// }
const getParentId = (list,iid)=>{
    for(let o of list || []) {
        if(o.name == iid) return o
        const o_ = getParentId(o.data, iid)
        if(o_) return o_
    }
}
export default getParentId