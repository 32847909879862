var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servicelist"},[_c('blm-header'),_c('TopSwiper'),_c('div',{staticClass:"container"},[_c('blm-bread',{attrs:{"breadList":_vm.breadList,"query":_vm.query}}),_c('a-back-top',{attrs:{"visibilityHeight":1000}},[_c('img',{staticClass:"slide3",attrs:{"src":require("../assets/images/top.png"),"alt":""}})]),_c('div',{staticClass:"bannerImg"},[_c('img',{attrs:{"src":_vm.list.bannerImg,"alt":""}})]),_c('div',{staticClass:"bannerContent"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.list.bannerContent)}})]),_c('div',{staticClass:"main clearfix"},[_c('div',{staticClass:"left clearfix"},[_c('div',{staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_vm._l((_vm.list.data),function(item,index){return _c('div',{key:index,class:{
                        btn: true,
                        [_vm.activeIndex === index ? 'active' : '']: true
                    },on:{"click":function($event){_vm.activeIndex = index}}},[_c('div',{staticClass:"ctitle"},[_vm._v(_vm._s(item.service))]),_c('div',{class:{
                            circle: true,
                            [_vm.activeIndex === index ? 'active' : '']: true
                        }})])})],2),_vm._l((_vm.list.data),function(item,index){return _c('div',{key:index,staticClass:"right"},[(index === _vm.activeIndex)?_c('div',[(item.serviceImg)?_c('img',{attrs:{"src":item.serviceImg,"alt":""}}):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(item.serviceContent)}}),(item.data)?_c('div',{staticClass:"titleAndContent"},_vm._l((item.data),function(listItem,index){return _c('div',{key:index,staticClass:"titleAndContentItem"},[_c('div',{staticClass:"titleAndContentTitle"},[_vm._v(" "+_vm._s(listItem.title)+" ")]),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(listItem.content)}})])}),0):_vm._e()]):_vm._e()])})],2),_c('div',{staticClass:"contactUs"},[_c('div',{staticClass:"imgAndUs",on:{"click":_vm.goContact}},[_c('img',{attrs:{"src":"https://oss.cloud.custouch.com/res/100940/2de704ef-e6c4-4482-b0fd-13d51e2b5b59.png","alt":""}}),_c('p',{staticStyle:{"font-weight":"550"}},[_vm._v("欢迎联系我们,立即来体验")])])])],1),_c('blm-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }