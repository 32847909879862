<template>
    <div class="search">
        <blm-header></blm-header>
        <div class="banner"></div>
        <div class="container">
            <blm-bread :breadList="breadList"></blm-bread>
            <div class="title">搜索结果</div>
            <div class="sousuo">
                <input
                    type="text"
                    v-model="searchInput"
                    @keyup.enter="searchFn"
                />
                <img
                    src="../assets/images/sousuo.png"
                    alt=""
                    @click="searchFn"
                />
            </div>
            <div v-if="!show" style="height: 250px"></div>
            <div class="main" v-if="show">
                <div v-if="result[0]">
                    <div
                        class="content"
                        v-for="(item, index) in result"
                        :key="index"
                    >
                        <div
                            v-if="
                                item.level1 === 'BLM服务' ||
                                    item.level1 === 'BLM产品'
                            "
                            @click="jump(item.path, item.name)"
                        >
                            <div class="subtitle">{{ item.name }}</div>
                            <div class="desc one-text">
                                {{ item.serviceContent }}
                            </div>
                        </div>
                        <div
                            v-if="item.path === 'industryList'"
                            @click="jump(item.path, item.level1)"
                        >
                            <div class="subtitle">{{ item.level1 }}</div>
                            <div class="desc one-text">{{ item.name }}</div>
                        </div>
                        <div
                            v-if="item.path === 'mediatype'"
                            @click="jump(item.path, item.level1)"
                        >
                            <div class="subtitle">{{ item.level1 }}</div>
                            <div class="desc one-text">{{ item.name }}</div>
                        </div>
                        <!-- <div  @click="jump(item.name,'')">
              <div class="subtitle">{{ item.name }}</div>
              <div class="desc one-text">{{ item.content }}</div>
            </div> -->
                    </div>
                </div>
                <p v-else>
                    没有<span>'{{ searchInput }}'</span>的搜索结果
                </p>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
// import industry from '../assets/data/industry'
// import media from '../assets/data/media'
// import {product} from '../assets/data/product'
export default {
    data() {
        return {
            searchInput: '',
            show: false,
            result: [],
            product: [],
            industry: [],
            media: []
        }
    },
    async created() {
        this.searchInput = this.$route.query.title
        // console.log(industry);
        await this.getData()
        this.getList()
    },
    methods: {
        jump(i, t) {
            if (t === '成功案例') {
                this.$router.push({
                    name: 'CaseList'
                })
                return
            }
            if (t === '平台优势') {
                this.$router.push({
                    name: 'Advantage'
                })
                return
            }
            if (!t) {
                this.$router.push({
                    name: 'FunctionDetail',
                    query: { title: i }
                })
                return
            }
            this.$router.push({
                name: i,
                query: {
                    title: t
                }
            })
        },
        async getData() {
            this.product = await this.$store.dispatch(
                'getJson',
                this.$root.path['产品和服务']
            )
            this.industry = await this.$store.dispatch(
                'getJson',
                this.$root.path['行业覆盖']
            )
            this.media = await this.$store.dispatch(
                'getJson',
                this.$root.path['媒体中心']
            )
        },
        getList() {
            this.industry.forEach(item => {
                return (item.path = 'industryList')
            })
            this.media.forEach(item => {
                return (item.path = 'mediatype')
            })
            this.product.forEach(item => {
                if (item.level1 === 'BLM服务') {
                    return (item.path = 'serviceList')
                } else {
                    return (item.path = 'productList')
                }
            })
        },
        searchFn() {
            if (this.searchInput) {
                this.show = true
                let list1 = this.industry.filter(i => {
                    return (
                        i.level1
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.name
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.mainTitle
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.projectSize
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.serviceItem
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.proOverview
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0
                    )
                })
                let list2 = this.media.filter(i => {
                    return (
                        i.level1
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.type
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.name
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0
                    )
                })
                let list3 = this.product.filter(i => {
                    return (
                        i.level1
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.name
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.level2
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.service
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0 ||
                        i.serviceContent
                            .toLowerCase()
                            .indexOf(this.searchInput.toLowerCase()) >= 0
                    )
                })
                let data = []
                let list4 = this.product.filter(i => {
                    if (i.data) {
                        if (i.data.listData) {
                            data = i.data.listData.filter(item => {
                                return (
                                    item.name
                                        .toLowerCase()
                                        .indexOf(
                                            this.searchInput.toLowerCase()
                                        ) >= 0 ||
                                    item.content
                                        .toLowerCase()
                                        .indexOf(
                                            this.searchInput.toLowerCase()
                                        ) >= 0
                                )
                            })
                        }
                    }
                })
                console.log(data)
                this.result = list1.concat(list2, list3, data)
            } else {
                this.show = false
            }
        }
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        }
    }
}
</script>

<style lang="less" scoped>
.search {
    // /deep/ .header {
    //     background: #fff;
    //     color: #2486CB;

    // }
    .banner {
        height: 92px;
    }
}

.one-text {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.container {
    padding-bottom: 112px;
    position: relative;
    width: 900px;
    margin: 0 auto;

    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }

    .sousuo {
        margin-top: 30px;

        input {
            width: 840px;
            height: 54px;
            border: 1px solid #d8d8d8;
            outline: none;
            font-size: 18px;
            line-height: 24px;
            // font-weight: 500;
            font-family: 'OPPOSM';
            color: #000;
            padding-left: 16px;
        }

        img {
            margin-left: 16px;
            width: 28px;
        }
    }

    .main {
        min-height: 250px;
        padding-top: 18px;

        p {
            font-size: 20px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            line-height: 31px;
            color: #000;

            span {
                color: #2486cb;
            }
        }

        .content {
            padding: 30px 0 28px 0;
            border-bottom: 1px solid #d8d8d8;

            &:last-child {
                border-bottom: 0px;
            }
        }

        .subtitle {
            font-size: 20px;
            line-height: 31px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #2486cb;
        }

        .desc {
            font-size: 16px;
            line-height: 32px;
            color: #4c4c4c;
            text-align: justify;
        }
    }
}
</style>
