<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    watch: {
        $route(to, from) {
            //监听路由是否变化
            // console.log(to ,from);
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            if (to.path === from.path) {
                if (to.query.title != from.query.title) {
                    location.reload()
                }
            }
        }
    }
}
</script>
<style lang="less">
#app {
    height: 100vh;
    font-family: 'OPPOSR';
}
</style>
