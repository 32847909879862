console.log(process.env.NODE_ENV)
export default process.env.NODE_ENV === 'production'
    ? {
          /* 我们的客户 */
          client: 'dae34320fafb8367',
          资料下载: '33218789dd47cbae',
          产品和服务: '157350171f87f689',
          行业覆盖: 'a7521e6bef9167fc',
          媒体中心: 'e811e0ce52d66cda',
          /* 公司荣誉 */
          honor: '2b20b1dcc58d9b63',
          公司战略: '6d9703f8322986f5',
          /* 联系我们 */
          contact: 'cb65a8b1f56c23a4',
          /* 公司概况 */
          survey: 'cd6b103f72a0affb',
      }
    : {
          client: '892055b91898cb4e',
          资料下载: '0d16301ffdb9ebd3',
          产品和服务: 'c17e9c8eec4f1391',
          行业覆盖: '99435e37239b76fb',
          媒体中心: 'd2f5a167d81dffae',
          honor: '56477c059e89947a',
          公司战略: 'fc6a1f03b7b4f9f2',
          contact: '97637d65858ce4f3',
          survey: 'b53f9c324dc30164',
      }
