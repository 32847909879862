<template>
    <div class="header">
        <div class="logo">
            <img
                src="../../assets/images/logo.png"
                alt
                @click="$router.push({ name: 'Home' })"
            />
        </div>
        <div class="container">
            <div class="tabs">
                <div
                    class="tab"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="goLink($event, item.title, item.name, item.link)"
                    data-category="click"
                    data-action="tab"
                    :data-label="item.link"
                >
                    <div>{{ item.title }}</div>
                    <div
                        :class="{
                            line: true,
                            [index === activeIndex ? 'active' : '']: true
                        }"
                        @mouseenter="activeIndex = index"
                        @mouseleave="activeIndex = ''"
                    >
                        <a-cascader
                            v-if="item.children"
                            :options="item.children"
                            @change="onChange"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="search">
            <div class="searchin" @click="hide = true" :class="{ show: hide }">
                <input
                    type="text"
                    v-model="searchInfo"
                    @blur="blurFn"
                    @keyup.enter="jump()"
                />
                <img src="../../assets/images/icon-search.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import mixin from '../mixins/mixin'
export default {
    mixins: [mixin],
    data() {
        return {
            list: [
                { title: '公司概况', name: 'survey' },
                {
                    title: '产品和服务',
                    name: '',
                    sunComponent: 'serviceList',
                    sun1Component: 'productList',
                    children: [
                        {
                            value: '产品和服务',
                            label: '产品和服务',
                            name: 'product'
                        }
                    ]
                },
                { title: '公司战略', name: 'strategy' },
                {
                    title: '行业覆盖',
                    sunComponent: 'industryList',
                    children: [
                        {
                            value: '行业覆盖',
                            label: '行业覆盖',
                            name: 'industry'
                        }
                    ]
                },
                {
                    title: '媒体中心',
                    sunComponent: 'mediatype',
                    children: [
                        { value: '媒体中心', label: '媒体中心', name: 'media' }
                    ]
                },
                { title: '资料下载', name: 'download' },
                { title: '联系我们', name: 'concact' },
                // { title: '拼模网', name: '', link: 'http://www.yesbim.com/' },
                {
                    title: 'EN',
                    name: '',
                    link:
                        'https://download.cloud.custouch.com/download/70baad8b3e8730c7'
                }
            ],
            activeIndex: '',
            showIndex: '',
            hide: false,
            searchInfo: ''
        }
    },
    async created() {
        const tabArr = this.list.filter(item => item.children)
        await Promise.all(
            tabArr.map(async item => {
                // console.log(this.$root.path[item.title])
                const list = await this.$store.dispatch(
                    'getJson',
                    this.$root.path[item.title]
                )
                const level1 = Array.from(new Set(list.map(ele => ele.level1)))
                if (item.title !== '产品和服务') {
                    level1.map(ele => {
                        item.children.push({
                            name: item.sunComponent,
                            value: ele,
                            label: ele
                        })
                    })
                } else {
                    level1.map(ele => {
                        item.children.push({
                            value: ele,
                            label: ele,
                            children: []
                        })
                    })
                    item.children.map(ele => {
                        const level2 = Array.from(
                            new Set(
                                list
                                    .filter(e => e.level1 === ele.label)
                                    .map(el => el.level2)
                            )
                        )

                        if (!level2.length) return
                        level2.map(el => {
                            if (ele.label === 'BLM产品') {
                                ele.children.push({
                                    name: item.sun1Component,
                                    value: el,
                                    label: el,
                                    children: []
                                })
                            } else {
                                ele.children.push({
                                    // name: item.sunComponent,
                                    value: el,
                                    label: el,
                                    children: []
                                })
                            }
                        })
                        ele.children.map(s => {
                            const level3 = Array.from(
                                new Set(
                                    list
                                        .filter(e => {
                                            return e.level2 === s.label
                                        })
                                        .map(el => el.name)
                                )
                            )

                            if (level3.length === 1 && level3[0] === s.label) {
                                if (!s.name) {
                                    s.name = item.sunComponent
                                }
                            } else {
                                level3.map(t => {
                                    s.children.push({
                                        name: item.sunComponent,
                                        value: t,
                                        label: t
                                    })
                                })
                            }
                        })
                    })
                }
            })
        )
    },
    methods: {
        blurFn() {
            this.hide = false
            this.searchInfo = ''
        },
        jump() {
            this.$router.push({
                name: 'search',
                query: {
                    title: this.searchInfo
                }
            })
        },
        goLink(event, t, n, link) {
            if (t === 'EN') {
                this.track(event, t)
            }
            if (link) {
                window.open(link)
            } else {
                if (n) {
                    this.$router.push({ name: n })
                }
            }
        },
        clickFn(i) {
            this.showIndex = i
        },
        onChange(value, select) {
            const title = value[value.length - 1]
            const name = select[select.length - 1].name
            console.log(title)
            if (title === '主要功能') {
                this.$router.push({ name: 'majorFunction' })
                return
            }
            if (title === '平台优势') {
                this.$router.push({ name: 'Advantage' })
                return
            }
            if (title === '成功案例') {
                this.$router.push({ name: 'CaseList' })
                return
            }
            if (
                title === '产品和服务' ||
                title === '行业覆盖' ||
                title === '媒体中心'
            ) {
                this.$router.push({ name })
                return
            }
            this.$router.push({
                name,
                query: {
                    title
                }
            })
        }
    }
}
</script>

<style lang="less" scoped rel="stylesheet/less">
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    min-width: 1200px;
    background: linear-gradient(rgba(0, 0, 0, 0.71), rgba(0, 0, 0, 0));
    .logo {
        float: left;
        width: 8%;
        height: 80px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
            width: 54px;
        }
    }

    .search {
        cursor: pointer;
        width: 10%;
        float: left;
        // text-align: center;
        .searchin {
            // width: 100%;
            position: relative;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            margin-top: 23px;
            border: 1px solid transparent;
            transition: all 0.5s ease;
            padding-left: 15px;
            &.show {
                border-color: #fff;
                width: 100%;
            }
        }
        input {
            width: 80%;
            background-color: transparent;
            border: none;
            outline: none;
            color: #fff;
            font-size: 16px;
            line-height: 30px;
            margin-top: 3px;
        }
        img {
            position: absolute;
            top: 7px;
            right: 8px;
            vertical-align: baseline;
            width: 26px;
        }
    }
    .border {
        border: 1px solid #fff;
    }
    .down {
        position: absolute;
        left: -16px;
        top: 46px;
        padding: 19px 20px 20px 20px;
        width: 295px;
        // height: 237px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: -1;
        .title {
            padding-bottom: 26px;
            text-align: left;
            font-size: 18px;
            line-height: 29px;
            color: #fff;
            // font-weight: bold;
            font-family: 'OPPOSB';
        }
        .child {
            text-align: left;
            .ctitle {
                float: left;
                margin-right: 5px;
                margin-bottom: 20px;
                // display: inline-block;
                text-align: left;
                font-size: 16px;
                // font-weight: bold;
                font-family: 'OPPOSB';

                line-height: 25px;
            }
            .sub {
                position: relative;
                float: left;
                padding-left: 40px;
            }
        }
        .triangel {
            position: absolute;
            top: 8px;
            left: 0px;
            width: 0px;
            height: 0px;
            border-top: 6px solid transparent;
            border-left: 10px solid #fff;
            border-bottom: 6px solid transparent;
        }
        .stitle {
            font-size: 16px;
            line-height: 36px;
            color: #fff;
        }
    }
}
.container {
    float: left;
    padding: 0 4%;
    margin: 0 auto;
    width: 82%;
    height: 80px;
    .tabs {
        padding-top: 30px;
        .tab {
            position: relative;
            height: 40px;
            float: left;
            font-size: 16px;
            color: rgba(255, 255, 255, 1);
            width: 11%;
            // padding: 0 2.5%;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            .line {
                width: 48px;
                height: 30px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
            }
            .active {
                border-bottom: 1px solid #fff;
                cursor: pointer;
            }
        }
    }
}
/deep/ .ant-cascader-picker {
    width: 70px;
    left: -15px;
    background-color: transparent;
    border: none;
    .ant-input {
        visibility: hidden;
    }
    .anticon svg {
        visibility: hidden;
    }
    /deep/ .ant-cascader-picker-clear {
        background-color: transparent;
    }
    /deep/ .ant-cascader-picker-clear:hover {
        background-color: transparent;
        color: transparent;
    }
}
/deep/ .ant-cascader-picker-label {
    visibility: hidden;
}
/deep/ .ant-cascader-menus-content {
    background-color: rgba(0, 0, 0, 0.6);
}
/deep/ .ant-cascader-menus {
    border-right: 0;
    background: rgba(0, 0, 0, 0.6) !important;
    .ant-cascader-menu {
        background: rgba(0, 0, 0, 0.6);
    }
}
/deep/ .ant-cascader-picker:hover .ant-cascader-picker-clear {
    opacity: 0;
}
</style>
