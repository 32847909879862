import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/configs/config'
import './assets/style/base.less'
import 'ant-design-vue/dist/antd.css'
import '@/assets/style/font.less'
import path from './assets/data/path'
import BlmFooter from './views/components/BlmFooter'
import BlmHeader from './views/components/BlmHeader'
import BlmBread from './views/components/BlmBread'

import { Breadcrumb, Select, Tree, Cascader, BackTop, message } from 'ant-design-vue'

Vue.use(Breadcrumb)
Vue.use(Select)
Vue.use(Tree)
Vue.use(Cascader)
Vue.use(BackTop )
Vue.prototype.$message = message

Vue.config.productionTip = false
Vue.component('blm-footer', BlmFooter)
Vue.component('blm-header', BlmHeader)
Vue.component('blm-bread', BlmBread)


new Vue({
  data() {
    return {
      scrollAnimation: false,
      path
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
