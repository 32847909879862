<template>
    <div class="servicelist">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/19626/官网-产品和服务-BLM服务banner2880x954.jpg"
                alt=""
            />
        </div>
        <div class="container">
            <blm-bread :breadList="breadList" :query="query"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide3" />
            </a-back-top>
            <div class="title">{{ query }}</div>
            <div class="intro" v-if="show">
                包括空调、通风、采暖系统；供电系统；给排水系统；消防系统；弱电系统；垂直运输系统
            </div>

            <div class="main clearfix">
                <div class="left clearfix">
                    <div class="slide"></div>
                    <div class="slide1"></div>
                    <div
                        :class="{
                            btn: true,
                            [activeIndex === index ? 'active' : '']: true
                        }"
                        v-for="(item, index) in list"
                        :key="index"
                        @click="activeIndex = index"
                    >
                        <div class="ctitle">{{ item.service }}</div>
                        <div
                            :class="{
                                circle: true,
                                [activeIndex === index ? 'active' : '']: true
                            }"
                        ></div>
                    </div>
                </div>

                <div class="right" v-for="(item, index) in list" :key="index">
                    <div v-if="index === activeIndex">
                        <div
                            v-if="item.serviceContent && activeIndex === index"
                        >
                            <p
                                v-for="(value, k) in item.serviceContent"
                                :key="k"
                                v-html="value"
                            >
                                <a
                                    v-if="item.serviceLink"
                                    :href="item.serviceLink"
                                    >了解更多</a
                                >
                            </p>
                        </div>
                        <img
                            v-if="item.serviceImg"
                            :src="item.serviceImg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import mixin from './mixins/mixin'
// import { product } from '../assets/data/product'
export default {
    mixins: [mixin],
    data() {
        return {
            list: [],
            activeIndex: 0
        }
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        },
        query() {
            return this.$route.query.title
        },
        show() {
            return this.$route.query.title === '机电顾问'
        }
    },
    created() {
        const title = `${this.$route.meta.title}-${this.$route.query.title}`
        this.pageTrack(title)
        this.getList()
    },
    methods: {
        async getList() {
            const product = await this.$store.dispatch(
                'getJson',
                this.$root.path['产品和服务']
            )
            this.list = product.filter(
                item => item.name === this.$route.query.title
            )
            this.list.forEach(item => {
                if (item.serviceContent) {
                    item.serviceContent =
                        item.serviceContent == '/'
                            ? item.serviceContent.replace('/', '')
                            : item.serviceContent.split('\n')
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.servicelist {
    .banner {
        img {
            width: 100%;
        }
    }
}
.intro {
    font-size: 16px;
    line-height: 32px;
    color: #4c4c4c;
    margin-top: 40px;
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.container {
    padding-bottom: 166px;
    position: relative;
    width: 900px;
    margin: 0 auto;
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        font-family: 'OPPOSM';
        // font-weight: 500;
        color: #000;
    }
    .slide3 {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
}
.main {
    margin-top: 52px;
    .left {
        position: relative;
        border-right: 1px solid #d8d8d8;
        float: left;
        padding-right: 29px;
        z-index: 1;
        .slide,
        .slide1 {
            position: absolute;
            right: -1px;
            height: 14px;
            width: 1px;
            background-color: #fff;
            z-index: 999;
        }
        .slide {
            top: 0;
        }
        .slide1 {
            bottom: 0;
        }

        .btn {
            position: relative;
            width: 158px;
            height: 42px;
            margin: 0 auto;
            padding: 0 10px;
            color: #878787;
            margin-bottom: 12px;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .ctitle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;
            line-height: 20px;
            text-align: left;
        }
        .circle {
            position: absolute;
            top: 14px;
            right: -36px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #d8d8d8;
        }
        .active {
            color: #fff;
            background-color: #2486cb;
            font-family: 'OPPOSB';
            border: none;
        }
    }
    .right {
        margin: 5px 0 0 220px;
        // float: left;
        font-size: 16px;
        color: rgba(76, 76, 76, 1);
        line-height: 32px;
        img {
            width: 675px;
            margin-top: 17px;
        }
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
