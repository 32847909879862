<template>
    <div>
        <div class="banner">
            <!-- <img src="../assets/ui/首页banner.png" alt="">     -->
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="(item, index) in banner"
                        :key="index"
                    >
                        <div class="banner" style="position: relative">
                            <img :src="item.url" alt="" class="bannerImg" />
                            <!-- <p> BLM Digital <br>
                                与您共创数字化世界</p> -->
                        </div>
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
export default {
    data() {
        return {
            banner: [
                {
                    url:
                        'https://oss.cloud.custouch.com/res/101945/54e59f47-b65d-429d-9457-6a5944891c5a.png'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/101944/71d96b6c-373d-457c-b559-763d54cc3ca1.png'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/101943/6c91b93d-586f-4c95-b5fb-a7756d5071d0.png'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/101942/3bf47652-b59f-4b71-8260-add9b35fcc1b.png'
                }
            ]
        }
    },
    mounted() {
        this.initSwiper()
        this.initSwiper1()
    },
    methods: {
        initSwiper() {
            new Swiper('.swiper-container', {
                loop: true,
                grabCursor: true,
                autoplay: {
                    delay: 3000, //时间
                    stopOnLastSlide: false, //是否停在最后一个
                    disableOnInteraction: false //触碰后是否停止切换
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    observer: true,
                    bulletClass: 'my-bullet',
                    bulletActiveClass: 'my-bullet-active'
                }
            })
        },
        initSwiper1() {
            console.log(123)
            new Swiper('.swiper-container1', {
                loop: true,
                slidesPerView: 'auto',
                loopedSlides: 4,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    delay: 2000, //时间
                    stopOnLastSlide: false, //是否停在最后一个
                    disableOnInteraction: false //触碰后是否停止切换
                },
                spaceBetween: 30,
                grabCursor: true
            })
        }
    }
}
</script>
<style lang="less" scoped>
.banner {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
    }

    p {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 53%;
        z-index: 999;
        line-height: 68px;
        font-size: 38px;
        // font-weight: bold;
        font-family: 'OPPOSB';
        color: #fff;
    }
}
.swiper-pagination {
    /deep/ .my-bullet {
        border-radius: none !important;
        display: inline-block;
        width: 50px;
        height: 2px;
        background-color: #fff;
        margin-right: 30px;
    }
    /deep/ .my-bullet-active {
        background-color: rgba(36, 134, 203, 1);
    }
}
</style>
