<template>
    <div class="survey">
        <blm-header></blm-header>
        <div class="banner">
            <img :src="detail.banner" alt=""/>
            <p v-html="detail.bannerText"></p>
        </div>
        <div class="contain">
            <div class="slidebox"></div>
            <div class="slidebox1"></div>
            <blm-bread :breadList="breadList"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide" />
            </a-back-top>
            <div class="company" v-if="detail.desc">
                <div class="title" ref="company">公司概况</div>
                <p class="content" v-for="item in detail.desc.split('\\n')" :key="item">
                    {{ item }}
                </p>
                <div class="intro clearfix">
                    <div
                        class="imgs"
                        v-for="(item, index) in list"
                        :key="index"
                    >
                        <img :src="item.img" alt="" />
                        <p class="imgtitle">{{ item.imgTitle }}</p>
                        <p>{{ item.imgSubtitle }}</p>
                    </div>
                </div>
            </div>
            <div class="honor">
                <div class="title" ref="honor">公司荣誉</div>
                <div class="subtitle sub1">高新技术企业证书</div>
                <div class="honor1">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="(item, index) of honor1"
                                :key="index"
                            >
                                <img :src="item.img" alt="" />
                            </div>
                        </div>
                    </div>
                    <div
                        class="swiper-button-prev swiper-button-prev1"
                        style="position:absolute"
                    ></div>
                    <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
                    <div class="swiper-button-next swiper-button-next1"></div>
                    <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
                </div>
                <div class="subtitle sub2">BIM获奖证书</div>
                <div class="honor2">
                    <div class="swiper-container1">
                        <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="(item, index) in honor2"
                                :key="index"
                            >
                                <img :src="item.img" alt="" />
                            </div>
                        </div>
                    </div>
                    <div
                        class="swiper-button-prev swiper-button-prev2"
                        style="position:absolute"
                    ></div>
                    <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
                    <div class="swiper-button-next swiper-button-next2"></div>
                    <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
                </div>
            </div>
            <div class="client">
                <div class="title" ref="client">{{ client[0].title }}</div>
                <p>{{ client[0].desc }}</p>
                <div class="client-imgs">
                    <div
                        class="img-wrap"
                        v-for="item in client"
                        :key="item.url"
                    >
                        <img :src="item.url" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
// import {client} from '../assets/data/client'
// import {honor} from '../assets/data/honor'
export default {
    data() {
        return {
            detail: {},
            list: [],
            // client: client,
            client: [],
            honor1: [],
            honor2: [],
            scroll: true,
            honorScroll: true,
            clientScroll: true
        }
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        }
    },
    created() {
        
    },
    async mounted() {
        await this.getData()
        this.$nextTick(() => {
            this.animotion()
        })
        window.addEventListener('scroll', this.detectScroll)
    },
    methods: {
        async getData() {
            this.list = await this.$store.dispatch(
                'getJson',
                this.$root.path['survey']
            )
            this.detail = this.list[0]
            this.client = await this.$store.dispatch(
                'getJson',
                this.$root.path['client']
            )
            const honor = await this.$store.dispatch(
                'getJson',
                this.$root.path['honor']
            )
            this.honor1 = honor.filter(
                item => item.desc1 === '高新技术企业证书'
            )
            this.honor2 = honor.filter(item => item.desc1 === 'BIM获奖证书')
            this.$nextTick(() => {
                this.initSwiper()
            })
        },
        initSwiper() {
            new Swiper('.swiper-container', {
                loop: true,
                slidesPerView: 'auto',
                loopedSlides: 4,
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1'
                },
                autoplay: {
                    delay: 2000, //时间
                    stopOnLastSlide: false, //是否停在最后一个
                    disableOnInteraction: false //触碰后是否停止切换
                },
                spaceBetween: 30,
                grabCursor: true
            })
            new Swiper('.swiper-container1', {
                loop: true,
                slidesPerView: 'auto',
                loopedSlides: 4,
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2'
                },
                autoplay: {
                    delay: 2000, //时间
                    stopOnLastSlide: false, //是否停在最后一个
                    disableOnInteraction: false //触碰后是否停止切换
                },
                spaceBetween: 30,
                grabCursor: true
            })
        },
        detectScroll() {
            this.animotion()
        },
        animotion() {
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            const company = this.$refs.company.getBoundingClientRect().top
            const honorTop = this.$refs.honor.getBoundingClientRect().top
            const clientTop = this.$refs.client.getBoundingClientRect().top
            if (company < clientH && this.scroll) {
                this.scroll = false
                anime({
                    targets: '.slidebox', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                anime({
                    targets:
                        '.company .title, .slidebox1, .company .content, .company .imgs', // 需要动画的元素
                    opacity: [0, 1],
                    translateY: [300, 0],
                    duration: 700, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 10 })
                })
            }
            if (honorTop < clientH && this.honorScroll) {
                this.honorScroll = false
                anime({
                    targets:
                        '.honor .title, .sub1, .swiper-button-prev1, .swiper-button-next1 ', // 需要动画的元素
                    opacity: [0, 1],
                    translateY: [300, 0],
                    duration: 700, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 100 })
                })
                anime({
                    targets: '.swiper-container', // 需要动画的元素
                    opacity: [0, 1],
                    duration: 300, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 1400 })
                })
                anime({
                    targets:
                        '.sub2, .swiper-button-prev2, .swiper-button-next2', // 需要动画的元素
                    opacity: [0, 1],
                    translateY: [300, 0],
                    duration: 700, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 1400 })
                })
                anime({
                    targets: '.swiper-container1', // 需要动画的元素
                    opacity: [0, 1],
                    duration: 300, // 动画的时长
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(100, { start: 2500 })
                })
            }
            if (clientTop < clientH && this.clientScroll) {
                this.clientScroll = false
                anime({
                    targets: '.client .title, .client p',
                    opacity: [0, 1],
                    translateY: [200, 0],
                    duration: 700,
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 300 })
                })
                anime({
                    targets: '.client img',
                    opacity: [0, 1],
                    // translateY: [200, 0],
                    duration: 700,
                    easing: 'easeInSine', //运动曲线
                    delay: anime.stagger(200, { start: 700 })
                })
            }
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    }
}
</script>

<style lang="less" scoped>
.client {
    .title {
        text-align: center;
        margin-bottom: 20px;
        opacity: 0;
    }
    p {
        opacity: 0;
        font-size: 16px;
        text-align: justify;
        line-height: 32px;
    }
    .client-imgs {
        padding: 36px 0 72px 0;
        .img-wrap {
            display: inline-block;
            width: 207px;
            margin-right: 24px;
            margin-bottom: 24px;
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        img {
            opacity: 0;
            width: 100%;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.banner {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
    }
    p {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 12%;
        z-index: 999;
        line-height: 68px;
        font-size: 38px;
        font-family: 'OPPOSB';
        // font-weight: bold;
        color: #fff;
    }
}
.contain {
    position: relative;
    width: 900px;
    margin: 0 auto;
    .slidebox {
        opacity: 0;
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slidebox1 {
        opacity: 0;
        position: absolute;
        top: 170px;
        left: -45px;
        width: 990px;
        height: 760px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
        .slide {
            width: 68px;
        }
    }
    .title {
        font-size: 32px;
        line-height: 64px;
        color: #000;
        margin-bottom: 36px;
        font-family: 'OPPOSM';
        // font-weight: 500;
        opacity: 0;
    }
    .content {
        opacity: 0;
        color: #4c4c4c;
        font-size: 16px;
        line-height: 32px;
    }
}
.intro {
    .imgs {
        opacity: 0;
        float: left;
        width: 280px;
        &:nth-child(n + 2) {
            margin-left: 30px;
        }
        img {
            width: 280px;
        }
        p {
            font-size: 16px;
            line-height: 30px;
            margin: 0;
            color: #000;
            text-align: center;
        }
        .imgtitle {
            margin-top: 17px;
            font-family: 'OPPOSM';
            // font-weight: 500;
        }
    }
}
.honor {
    .title {
        text-align: center;
        margin: 120px 0 24px 0;
    }
    .subtitle {
        text-align: center;
        font-size: 20px;
        line-height: 26px;
        color: #000;
        margin-bottom: 28px;
        opacity: 0;
    }
}
.honor1 {
    margin-bottom: 64px;
    position: relative;
    .swiper-container {
        width: 900px;
        overflow: hidden;
        opacity: 0;
        .swiper-slide {
            width: 279px;
            img {
                width: 279px;
            }
        }
    }
    .swiper-button-prev1,
    .swiper-button-next1 {
        outline: none;
        opacity: 0;
    }
    .swiper-button-prev1:after {
        left: -50px;
    }
    .swiper-button-next1:after {
        right: -50px;
    }
    .swiper-button-prev1:after,
    .swiper-button-next1:after {
        font-size: 16px;
        position: absolute;
        top: 10px;
    }
}
.honor2 {
    position: relative;
    margin-bottom: 96px;
    .swiper-container1 {
        opacity: 0;
        width: 900px;
        overflow: hidden;
        .swiper-slide {
            width: 279px;
            img {
                width: 279px;
            }
        }
    }
    .swiper-button-prev2,
    .swiper-button-next2 {
        outline: none;
        opacity: 0;
    }
    .swiper-button-prev2:after {
        left: -50px;
    }
    .swiper-button-next2:after {
        right: -50px;
    }
    .swiper-button-prev2:after,
    .swiper-button-next2:after {
        font-size: 16px;
        position: absolute;
        top: 10px;
    }
}
</style>
