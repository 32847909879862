<template>
    <div class="servicelist">
        <blm-header></blm-header>
        <TopSwiper />
        <div class="container">
            <blm-bread :breadList="breadList" :query="query"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide3" />
            </a-back-top>
            <!-- <div class="title">{{ query }}</div> -->
            <div class="bannerImg">
                <img :src="list.bannerImg" alt="" />
            </div>
            <div class="bannerContent">
                <div v-html="list.bannerContent"></div>
            </div>

            <div class="main clearfix">
                <div class="left clearfix">
                    <div class="slide"></div>
                    <div class="slide1"></div>
                    <div
                        :class="{
                            btn: true,
                            [activeIndex === index ? 'active' : '']: true
                        }"
                        v-for="(item, index) in list.data"
                        :key="index"
                        @click="activeIndex = index"
                    >
                        <div class="ctitle">{{ item.service }}</div>
                        <div
                            :class="{
                                circle: true,
                                [activeIndex === index ? 'active' : '']: true
                            }"
                        ></div>
                    </div>
                </div>

                <div
                    class="right"
                    v-for="(item, index) in list.data"
                    :key="index"
                >
                    <div v-if="index === activeIndex">
                        <img
                            v-if="item.serviceImg"
                            :src="item.serviceImg"
                            alt=""
                        />
                        <div v-html="item.serviceContent"></div>
                        <div class="titleAndContent" v-if="item.data">
                            <div
                                class="titleAndContentItem"
                                v-for="(listItem, index) in item.data"
                                :key="index"
                            >
                                <div class="titleAndContentTitle">
                                    {{ listItem.title }}
                                </div>
                                <div
                                    class="content"
                                    v-html="listItem.content"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contactUs">
                <div class="imgAndUs" @click="goContact">
                    <img
                        src="https://oss.cloud.custouch.com/res/100940/2de704ef-e6c4-4482-b0fd-13d51e2b5b59.png"
                        alt=""
                    />
                    <p style="font-weight:550">欢迎联系我们,立即来体验</p>
                </div>
            </div>
        </div>

        <blm-footer></blm-footer>
    </div>
</template>

<script>
import mixin from './mixins/mixin'
import getParentId from '@/service/recursion.js'
import TopSwiper from './components/TopSwiper.vue'
export default {
    mixins: [mixin],
    data() {
        return {
            list: {},
            activeIndex: 0
        }
    },
    components: {
        TopSwiper
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        },
        query() {
            return this.$route.query.title
        },
        show() {
            return this.$route.query.title === '机电顾问'
        }
    },
    created() {
        const title = `${this.$route.meta.title}-${this.$route.query.title}`
        this.getList()
    },
    methods: {
        goContact() {
            window.location.href =
                'https://form.cloud.custouch.com/show/991949376479997952'
        },
        async getList() {
            const product = await this.$store.dispatch(
                'getJson',
                this.$root.path['产品和服务']
            )
            product.map(item => {
                if (item.name == '主要功能') {
                    item.data.listData.map(item => {
                        if (item.name == this.$route.query.title) {
                            this.list = item
                        }
                    })
                }
            })
            console.log(this.list)
        }
    }
}
</script>

<style lang="less" scoped>
.servicelist {
    .banner {
        img {
            width: 100%;
        }
    }
}

.intro {
    font-size: 16px;
    line-height: 32px;
    color: #4c4c4c;
    margin-top: 40px;
}

.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.container {
    padding-bottom: 20px;
    position: relative;
    width: 900px;
    margin: 0 auto;

    .bannerImg {
        img {
            width: 100%;
        }
    }

    .bannerContent {
        margin-top: 57px;
        line-height: 32px;
    }

    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        font-family: 'OPPOSM';
        // font-weight: 500;
        color: #000;
    }

    .slide3 {
        width: 68px;
    }

    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
}

.main {
    margin-top: 52px;
    // height: 500px;

    .left {
        position: relative;
        border-right: 1px solid #d8d8d8;
        float: left;
        padding-right: 29px;
        z-index: 1;

        .slide,
        .slide1 {
            position: absolute;
            right: -1px;
            height: 14px;
            width: 1px;
            background-color: #fff;
            z-index: 999;
        }

        .slide {
            top: 0;
        }

        .slide1 {
            bottom: 0;
        }

        .btn {
            position: relative;
            width: 158px;
            height: 42px;
            margin: 0 auto;
            padding: 0 10px;
            color: #878787;
            margin-bottom: 12px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .ctitle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;
            line-height: 20px;
            text-align: left;
        }

        .circle {
            position: absolute;
            top: 14px;
            right: -36px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #d8d8d8;
        }

        .active {
            color: #fff;
            background-color: #2486cb;
            font-family: 'OPPOSB';
            border: none;
        }
    }

    .right {
        margin: 5px 0 0 220px;
        // float: left;
        font-size: 16px;
        color: rgba(76, 76, 76, 1);
        line-height: 32px;

        img {
            width: 675px;
            margin: 17px 0;
        }

        .titleAndContent {
            .titleAndContentItem:nth-child(n + 2) {
                margin-top: 30px;
            }

            .titleAndContentTitle {
                color: #2486cb;
                font-family: OPPOSans-B;
                font-weight: 550;
            }
        }
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.contactUs {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .imgAndUs {
        text-align: center;
    }

    img {
        width: 86px;
    }

    p {
        margin-top: 10px;
        text-align: center;
        font-family: OPPOSans-B;
        font-weight: B;
        font-size: 16px;
        color: #197fc1;
        letter-spacing: 0;
        text-align: justify;
        line-height: 32px;
    }
}
</style>
