var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media"},[_c('blm-header'),_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"slidebox slidebox1"}),_c('div',{staticClass:"slidebox slidebox2"}),_c('div',{staticClass:"slidebox slidebox3"}),_c('blm-bread',{attrs:{"breadList":_vm.breadList}}),_c('div',{ref:"slide",staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_c('div',{staticClass:"title btitle"},[_vm._v("媒体中心")]),_c('p',{staticClass:"intro pintro"},[_vm._v(" 在这里，您可以查询论坛、视频、新闻资讯等媒体活动。 ")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
                'type',
                'clearfix',
                { type1: index === 0 },
                { type2: index === 1 },
                { type3: index === 2 }
            ]},[_c('div',{ref:"type",refInFor:true,staticClass:"img"},[_c('img',{staticClass:"img1",attrs:{"src":item.url,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"subtitle intro"},[_vm._v(_vm._s(item.subtitle))]),_c('a',{staticClass:"more",on:{"mouseover":function($event){item.hidemore = true},"mouseout":function($event){item.hidemore = false},"click":function($event){return _vm.jump(item.title)}}},[_vm._v("了解更多 "),_c('img',{class:{ show: item.hidemore },attrs:{"src":require("../assets/images/arrow.png"),"alt":""}})])])])})],2),_c('blm-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":"https://oss.cloud.custouch.com/res/99741/官网-媒体中心头图.jpg","alt":""}}),_c('p',[_vm._v(" BLM Digital "),_c('br'),_vm._v(" 与您共创数字化世界 ")])])
}]

export { render, staticRenderFns }