<template>
    <div class="industrylist">
        <blm-header></blm-header>
        <div class="banner">
            <img :src="banner" alt="" />
        </div>
        <div class="container">
            <blm-bread :breadList="breadList" :query="query"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide" />
            </a-back-top>
            <!-- <div v-if="inindustry[0].name"> -->
            <div class="item" v-for="(item, index) in industry" :key="index">
                <template v-if="item.name">
                    <div class="title">{{ item.name }}</div>
                    <p class="intro">{{ item.mainTitle }}</p>
                    <p class="intro" v-if="item.projectSize">
                        <span>项目规划:&nbsp;</span>{{ item.projectSize }}
                    </p>
                    <p class="intro" v-if="item.serviceItem">
                        <span>服务内容: </span>{{ item.serviceItem }}
                    </p>
                    <p class="intro" v-if="item.proOverview">
                        <span>项目概况: </span><span v-html="item.proOverview"></span>
                    </p>
                    <img
                        :src="p"
                        alt=""
                        v-for="(p, n) in item.proImgs"
                        :key="n"
                    />
                </template>
                <div v-else class="hope">敬请期待</div>
            </div>
            <!-- </div>  -->
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import mixin from './mixins/mixin'
// import industry from '../assets/data/industry'
export default {
    mixins: [mixin],
    data() {
        return {
            list: [],
            industry: []
        }
    },
    created() {
        const title = `${this.$route.meta.title}-${this.$route.query.title}`
        this.pageTrack(title)
        this.getIndustry()
        // console.log(industry.filter(item => item.level1 === this.$route.query.title)[0].banner);
    },
    methods: {
        async getIndustry() {
            this.list = await this.$store.dispatch(
                'getJson',
                this.$root.path['行业覆盖']
            )
            let arr = this.list.filter(
                item => item.level1 === this.$route.query.title
            )
            // console.log(arr);
            arr.forEach(item => {
                if (item.proImgs) {
                    item.proImgs =
                        item.proImgs.indexOf(';') > -1
                            ? item.proImgs.toString().split(';')
                            : item.proImgs.toString().split('；')
                }
            })
            this.industry = arr
        }
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        },
        query() {
            return this.$route.query.title
        },
        banner() {
            if (!this.list.length) return
            return this.list.filter(
                item => item.level1 === this.$route.query.title
            )[0].banner
        }
    }
}
</script>

<style lang="less" scoped>
.industrylist {
    .banner {
        img {
            width: 100%;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.container {
    padding-bottom: 8px;
    position: relative;
    width: 900px;
    margin: 0 auto;
    .slide {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
    .title {
        margin-bottom: 24px;
        font-size: 28px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .item {
        margin-bottom: 96px;
        .intro {
            font-size: 16px;
            line-height: 32px;
            color: #4c4c4c;
        }
        img {
            width: 899px;
            margin-top: 24px;
            text-align: center;
        }
    }
    .hope {
        font-size: 20px;
        height: 100px;
        text-align: center;
    }
}
</style>
