var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media"},[_c('blm-header'),_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"slidebox slidebox1"}),_c('div',{staticClass:"slidebox slidebox2"}),_c('div',{staticClass:"slidebox slidebox3"}),_c('blm-bread',{attrs:{"breadList":_vm.breadList}}),_c('div',{ref:"slide",staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_c('div',{staticClass:"title btitle"},[_vm._v("数字化建筑资产管理平台")]),_c('p',{staticClass:"intro pintro"},[_vm._v(" 数字化建筑资产管理平台提供空间资产运营、楼宇设施管理、智能化集成管理于一体的“产品+服务”模式，实现建筑资产的闭环管理。 ")]),_vm._l((_vm.productData),function(item,index){return _c('div',{key:index,class:[
                'type',
                'clearfix',
                { type1: index === 0 },
                { type2: index === 1 },
                { type3: index === 2 }
            ]},[_c('div',{ref:"type",refInFor:true,staticClass:"img"},[_c('img',{staticClass:"img1",attrs:{"src":item.productBanner,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"blueRectangle subtitle"}),_c('a',{staticClass:"more",on:{"mouseover":function($event){item.hidemore = true},"mouseout":function($event){item.hidemore = false},"click":function($event){return _vm.jump(item.name)}}},[_vm._v("了解更多 "),_c('img',{class:{ show: item.hidemore },attrs:{"src":require("../assets/images/arrow.png"),"alt":""}})])])])})],2),_c('blm-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":"https://oss.cloud.custouch.com/res/100243/位图备份_2.png","alt":""}})])
}]

export { render, staticRenderFns }