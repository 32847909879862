<template>
    <div class="home">
        <blm-header></blm-header>
        <div class="banner">
            <!-- <img src="../assets/ui/首页banner.png" alt="">     -->
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="(item, index) in banner"
                        :key="index"
                    >
                        <div class="banner" style="position: relative">
                            <img :src="item.url" alt="" class="bannerImg" />
                            <p>
                                BLM Digital <br />
                                与您共创数字化世界
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>
        </div>

        <div class="container">
            <div ref="slide" class="slidebox1"></div>
            <div class="slidebox2"></div>
            <div class="slidebox3"></div>
            <div class="slidebox4"></div>
            <div class="slidebox5"></div>
            <div class="slidebox6"></div>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide" />
            </a-back-top>
            <div class="concept">
                <div class="title">什么是BLM</div>
                <div class="content">
                    <p style="margin-bottom: 8px">
                        我们是建筑数字化的专业服务商。
                    </p>
                    <p>
                        我们提供BLM建筑全生命周期的管理，结合工程建设各阶段应用服务，运用新一代信息技术实现建筑数字化，助力建筑资产保值增值，为健康建筑保驾护航。
                    </p>
                </div>
                <a
                    class="more"
                    @mouseover="hide = true"
                    @mouseout="hide = false"
                    @click="$router.push({ name: 'concept' })"
                    >了解更多
                    <img
                        src="../assets/images/arrow.png"
                        alt=""
                        :class="{ show: hide }"
                    />
                </a>
            </div>
            <div class="product">
                <div class="img">
                    <img
                        ref="proImg"
                        src="https://oss.cloud.custouch.com/res/18969/官网-首页-产品服务648x828.jpg"
                        class="img1"
                        alt=""
                    />
                </div>
                <div class="service">
                    <div class="title">产品服务</div>
                    <div class="rect"></div>
                    <div class="content">
                        截止目前公司已累计提供了超过2000万平方米总建筑面积的BIM模型，服务超过220个项目，达到运维阶段平台服务的近500万平方米。公司自主研发的中枢平台贯穿建筑全生命周期，以BIM模型和建筑大数据为基础，构建统一的数据采集、数据存储、3D渲染/AI能力、基础性应用等功能，以及对第三方的开放能力，是建筑数字化、智慧化的关键节点和必经之路。BLM中枢平台深耕B端客户，形成“产品+服务”的可持续闭环的商业模式。
                    </div>
                    <a
                        class="more"
                        @mouseover="show = true"
                        @mouseout="show = false"
                        @click="$router.push({ name: 'product' })"
                        >了解更多
                        <img
                            src="../assets/images/arrow.png"
                            alt=""
                            :class="{ show: show }"
                        />
                    </a>
                </div>
            </div>
            <div class="company clearfix">
                <div class="plan">
                    <div class="title">公司战略</div>
                    <div class="rect"></div>
                    <div class="content">
                        公司致力于建筑数字化的专业服务商，提供BLM建筑全生命周期的管理，结合工程建设各阶段应用服务，并运用新一代信息技术（AI、5G、云计算等）实现建筑数字化，助力建筑资产保值增值，为健康建筑保驾护航。
                    </div>
                    <a
                        class="more"
                        @mouseover="hidemore = true"
                        @mouseout="hidemore = false"
                        @click="$router.push({ name: 'strategy' })"
                        >了解更多
                        <img
                            src="../assets/images/arrow.png"
                            alt=""
                            :class="{ show: hidemore }"
                        />
                    </a>
                </div>
                <div class="img" ref="comImg">
                    <img
                        src="https://oss.cloud.custouch.com/res/18972/官网-首页-公司战略968x616.jpg"
                        class="img1"
                        alt=""
                    />
                </div>
            </div>

            <div class="industry">
                <div class="center">
                    <div class="title" ref="industry">行业覆盖</div>
                    <div class="rect"></div>
                </div>
                <div class="content clearfix">
                    <div
                        class="module"
                        v-for="(item, index) in industry"
                        :key="index"
                        @click="clickfn(item.title, item.name)"
                    >
                        <div>
                            <img :src="item.url" alt="" class="img1" />
                        </div>
                        <p>{{ item.title }}</p>
                    </div>
                </div>
                <div class="morebox">
                    <a
                        class="more"
                        @mouseover="showmore = true"
                        @mouseout="showmore = false"
                        @click="$router.push({ name: 'industry' })"
                        >更多行业覆盖
                        <img
                            src="../assets/images/arrow.png"
                            alt=""
                            :class="{ show: showmore }"
                        />
                    </a>
                </div>
            </div>

            <div class="media">
                <div class="title" ref="media">媒体中心</div>
                <div class="rect"></div>
                <div class="content clearfix">
                    <div
                        class="module"
                        v-for="(item, index) in media"
                        :key="index"
                        @click="clickfn(item.title, item.name)"
                    >
                        <div>
                            <img :src="item.url" alt="" class="img1" />
                        </div>
                        <div class="abstract">
                            <p>{{ item.title }}</p>
                            <a
                                class="more"
                                @mouseover="item.hide = true"
                                @mouseout="item.hide = false"
                                @click="jump(item.title)"
                                >了解更多
                                <img
                                    src="../assets/images/arrow.png"
                                    alt=""
                                    :class="{ show: item.hide }"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="our">
            <div class="client">
                <div class="title" ref="client">我们的客户</div>
                <div class="rect"></div>
                <div class="swiper-button-prev" style="position:absolute"></div>
                <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
                <div class="swiper-container1">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(item, index) in client"
                            :key="index"
                        >
                            <img :src="item.url" alt="" />
                        </div>
                    </div>
                </div>
                <div class="swiper-button-next"></div>
                <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import anime from 'animejs'
// import { client } from '../assets/data/client'
export default {
    data() {
        return {
            industry: [
                {
                    url:
                        'https://oss.cloud.custouch.com/res/18974/官网-首页-园区390x320.jpg',
                    title: '园区',
                    name: 'industryList'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/18975/官网-首页-商办390x320.jpg',
                    title: '商办',
                    name: 'industryList'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/18963/官网-首页-住宅390x320.jpg',
                    title: '住宅',
                    name: 'industryList'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/18968/官网-首页-学校390x320.jpg',
                    title: '学校',
                    name: 'industryList'
                }
            ],
            media: [
                {
                    url:
                        'https://oss.cloud.custouch.com/res/18967/官网-首页-专题论坛560x314.jpg',
                    title: '专题论坛',
                    name: 'mediatype',
                    hide: false
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/18961/官网-首页-视频中心560x314.jpg',
                    title: '视频中心',
                    name: 'mediatype',
                    hide: false
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/18962/官网-首页-新闻报道560x314.jpg',
                    name: 'mediatype',
                    title: '新闻报道',
                    hide: false
                }
            ],
            banner: [
                {
                    url:
                        'https://oss.cloud.custouch.com/res/19607/%E9%A6%96%E9%A1%B5banner.png'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/19605/%E9%A6%96%E9%A1%B5banner-2.png'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/19606/%E9%A6%96%E9%A1%B5banner-3.png'
                },
                {
                    url:
                        'https://oss.cloud.custouch.com/res/102765/官网-新增轮播图.png'
                }
            ],
            // timer: null, // 定时器
            // client: client,
            client: [],
            hide: false,
            show: false,
            hidemore: false,
            showmore: false,
            slideScroll: false,
            scroll: false,
            scrollCom: false,
            scrollIns: false,
            scrollMedia: false,
            scrollClient: false,
            scrollFoot: false
        }
    },
    created() {
        this.getClient()
        // this.timer = setInterval(() => {
        // if(document.readyState === 'complete') {
        //   this.animeMotion()
        // }

        // }, 100);
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)
        this.initSwiper()
        if (this.$root.scrollAnimotion) {
            this.animeMotion()
        } else {
            const bannerImg = document.getElementsByClassName('bannerImg')
            // console.log(bannerImg[0]);
            bannerImg[0].onload = () => {
                this.animeMotion()
                this.$root.scrollAnimotion = true
            }
        }
    },
    methods: {
        async getClient() {
            this.client = await this.$store.dispatch(
                'getJson',
                this.$root.path['client']
            )
            this.$nextTick(() => {
                this.initSwiper1()
            })
        },
        initSwiper() {
            new Swiper('.swiper-container', {
                loop: true,
                grabCursor: true,
                autoplay: {
                    delay: 3000, //时间
                    stopOnLastSlide: false, //是否停在最后一个
                    disableOnInteraction: false //触碰后是否停止切换
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    observer: true,
                    bulletClass: 'my-bullet',
                    bulletActiveClass: 'my-bullet-active'
                }
            })
        },
        initSwiper1() {
            new Swiper('.swiper-container1', {
                loop: true,
                slidesPerView: 'auto',
                loopedSlides: 4,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    delay: 2000, //时间
                    stopOnLastSlide: false, //是否停在最后一个
                    disableOnInteraction: false //触碰后是否停止切换
                },
                spaceBetween: 30,
                grabCursor: true
            })
        },
        jump(title) {
            this.$router.push({
                name: 'mediatype',
                query: {
                    title
                }
            })
        },
        clickfn(title, name) {
            this.$router.push({
                name,
                query: {
                    title
                }
            })
        },
        // translateY
        animeted(targets, start) {
            anime({
                targets, // 需要动画的元素
                opacity: [0, 1],
                translateY: [300, 0],
                duration: 700, // 动画的时长
                easing: 'easeInSine', //运动曲线
                delay: anime.stagger(200, start)
            })
        },
        // opacity
        showAnime(targets, start) {
            anime({
                targets, // 需要动画的元素
                opacity: [0, 1],
                duration: 1000, // 动画的时长
                easing: 'easeInSine', //运动曲线
                delay: anime.stagger(200, start)
            })
        },
        animeMotion() {
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            const slideTop =
                this.$refs.slide && this.$refs.slide.getBoundingClientRect().top
            const proTop = this.$refs.proImg.getBoundingClientRect().top
            const comTop = this.$refs.comImg.getBoundingClientRect().top
            const indusTop = this.$refs.industry.getBoundingClientRect().top
            const mediaTop = this.$refs.media.getBoundingClientRect().top
            const clientTop = this.$refs.client.getBoundingClientRect().top
            if (slideTop < clientH && !this.slideScroll) {
                this.slideScroll = true
                const target =
                    '.slidebox2, .concept .title, .concept .content, .concept a'
                anime({
                    targets: '.slidebox1', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.animeted(target, { start: 0 })
            }
            if (proTop < clientH && !this.scroll) {
                this.scroll = true
                const target = [
                    '.product .img',
                    '.service .title',
                    '.service .rect',
                    '.service .content',
                    '.service .more'
                ]
                this.animeted(target, { start: 100 })
            }
            if (comTop < clientH && !this.scrollCom) {
                this.scrollCom = true
                const target = [
                    '.company .img',
                    '.plan .title',
                    '.plan .rect',
                    '.plan .content',
                    '.plan .more'
                ]
                anime({
                    targets: '.slidebox3', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.animeted(target, { start: 600 })
            }
            if (indusTop < clientH && !this.scrollIns) {
                this.scrollIns = true
                const target = [
                    '.industry .title',
                    '.industry .rect',
                    '.industry .content .module',
                    '.industry .more'
                ]
                this.showAnime(['.slidebox4', '.slidebox5'], { start: 10 })
                this.animeted(target, { start: 700 })
            }
            if (mediaTop < clientH && !this.scrollMedia) {
                this.scrollMedia = true
                const target = [
                    '.media .title',
                    '.media .rect',
                    '.media .module'
                ]
                this.showAnime('.slidebox6', { start: 10 })
                this.animeted(target, { start: 500 })
            }
            if (clientTop < clientH && !this.scrollClient) {
                this.scrollClient = true
                const target = [
                    '.our',
                    '.our .title',
                    '.our .rect',
                    '.swiper-button-prev',
                    '.swiper-button-next'
                ]
                this.animeted(target, { start: 100 })
                this.showAnime('.swiper-wrapper', { start: 1400 })
                this.showAnime('.footer', { start: 1800 })
            }
        },
        detectScroll() {
            this.animeMotion()
            const left = -document.documentElement.scrollLeft
            document.getElementsByClassName('header')[0].style.left =
                left + 'px'
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
        // window.clearInterval(this.timer)
    }
}
</script>

<style lang="less" scoped>
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.home {
    position: relative;
    overflow: hidden;

    .swiper-container {
        width: 100%;
        img {
            width: 100%;
        }
        .banner {
            position: relative;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
            }
            p {
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                left: 53%;
                z-index: 999;
                line-height: 68px;
                font-size: 38px;
                // font-weight: bold;
                font-family: 'OPPOSB';
                color: #fff;
            }
        }
        .swiper-pagination {
            /deep/ .my-bullet {
                border-radius: none !important;
                display: inline-block;
                width: 50px;
                height: 2px;
                background-color: #fff;
                margin-right: 30px;
            }
            /deep/ .my-bullet-active {
                background-color: rgba(36, 134, 203, 1);
            }
        }
    }
    .container {
        position: relative;
        width: 900px;
        margin: 0 auto;
    }
    .slidebox1 {
        opacity: 0;
        width: 382px;
        height: 298px;
        position: absolute;
        top: 30px;
        left: -35%;
        z-index: -1;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slidebox2 {
        opacity: 0;
        width: 484px;
        height: 282px;
        position: absolute;
        top: 210px;
        left: -10%;
        z-index: -1;
        background-color: rgba(145, 205, 240, 0.1);
    }
    .slidebox3 {
        opacity: 0;
        width: 506px;
        height: 291px;
        background-color: rgba(248, 248, 248, 1);
        position: absolute;
        top: 975px;
        // top: 1450px;
        right: -30%;
        z-index: -1;
    }
    .slidebox4 {
        opacity: 0;
        width: 630px;
        height: 241px;
        background-color: rgba(145, 205, 240, 0.15);
        position: absolute;
        top: 1549px;
        left: -15%;
        z-index: -3;
    }
    .slidebox5 {
        opacity: 0;
        width: 630px;
        height: 275px;
        background-color: rgba(145, 205, 240, 0.15);
        position: absolute;
        top: 1395px;
        right: -15%;
        z-index: -1;
    }
    .slidebox6 {
        opacity: 0;
        width: 269px;
        height: 446px;
        background-color: #f8f8f8;
        position: absolute;
        top: 1900px;
        left: -5%;
        z-index: -1;
    }
    .slide {
        width: 68px;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
    }
}
.img1 {
    transition: all 1s ease;
    &:hover {
        transform: scale(2);
    }
}
.more {
    opacity: 0;
    color: #2486cb;
    line-height: 21px;
    font-size: 16px;
    font-family: 'OPPOSM';
    cursor: pointer;
    img {
        margin-left: 10px;
        transition: all 0.8s ease;
        width: 22px;
        opacity: 0;
        transform: translateX(-10px);
        &.show {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.title {
    opacity: 0;
    font-size: 32px;
    color: #000;
    font-family: 'OPPOSM';
}
.content {
    opacity: 0;
    font-size: 16px;
    color: #4c4c4c;
    line-height: 32px;
    text-align: justify;
    margin-bottom: 24px;
}
.rect {
    opacity: 0;
    width: 64px;
    height: 12px;
    background-color: #91cdf0;
    margin: 16px 0;
}
.concept {
    padding-top: 30px;
    margin-left: calc(8% + 30px);
    width: 627px;
    .title {
        line-height: 64px;
        opacity: 0;
    }
    .content {
        margin-top: 8px;
    }
}
.product {
    margin-top: 140px;
    height: 415px;
    .service {
        float: left;
    }
    .img {
        opacity: 0;
        height: 414px;
        overflow: hidden;
        width: 324px;
        float: left;
        margin-right: 130px;
    }
    img {
        height: 100%;
    }
    .content {
        width: 350px;
        opacity: 0;
    }
}
.company {
    padding-top: 96px;
    width: 100%;
    .plan {
        float: left;
        width: 337px;
    }
    .img {
        opacity: 0;
        float: right;
        width: 484px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .more {
        img {
            width: 22px;
            float: none;
        }
    }
}
.industry {
    width: 100%;
    margin-top: 160px;
    .center {
        margin-left: 437px;
        width: 130px;
    }
    .title {
        text-align: center;
    }
    .morebox {
        text-align: center;
        position: relative;
        height: 22px;
        .more {
            position: absolute;
            top: 0;
            right: 255px;
        }
    }
    .content {
        opacity: 1;
        margin-top: 54px;
        margin-bottom: 34px;
    }
    .module {
        opacity: 0;
        cursor: pointer;
        float: left;
        width: 195px;
        height: 210px;
        box-shadow: 0 2px 4px 0 #dee4e8;
        background-color: #fff;
        &:nth-child(n + 2) {
            margin-left: 40px;
        }
        div {
            overflow: hidden;
        }
        img {
            width: 100%;
        }
        p {
            color: #000;
            text-align: center;
            height: 49px;
            line-height: 49px;
            font-size: 20px;
            &:hover {
                color: #2486cb;
            }
        }
    }
}
.media {
    margin: 160px 0 150px 0;
    .content {
        padding-top: 38px;
        opacity: 1;
    }
    .module {
        float: left;
        width: 280px;
        height: 244px;
        box-shadow: 0 2px 4px 0 #dee4e8;
        background-color: #fff;
        &:nth-child(n + 2) {
            margin-left: 30px;
        }
        div {
            overflow: hidden;
            .img1 {
                width: 100%;
            }
        }
    }
    .abstract {
        padding-left: 12px;
        p {
            margin: 0;
            padding: 16px 0 0 0;
            color: #000;
            font-size: 20px;
        }
    }
    .more {
        opacity: 1;
        img {
            transition: all 0.8s ease;
            width: 22px;
            opacity: 0;
            transform: translateX(-10px);
            &.show {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}
.our {
    width: 100%;
    background-color: rgba(145, 205, 240, 0.1);
    padding: 56px 0;
    .swiper-button-prev,
    .swiper-button-next {
        outline: none;
    }
    .swiper-button-prev:after {
        left: -50px;
    }
    .swiper-button-next:after {
        right: -50px;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 16px;
        position: absolute;
        top: 62px;
    }
    .client {
        position: relative;
        margin: 0 auto;
        width: 900px;
        .swiper-container1 {
            width: 900px;
            margin-top: 38px;
            overflow: hidden;
            img {
                width: 100%;
            }
            .swiper-slide {
                width: 252px;
                img {
                    width: 252px;
                }
            }
        }
    }
}
</style>
