import Vue from 'vue'
import Vuex from 'vuex'
import { getJson } from '../service'
import { axios } from '../service/config'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        fileData: {}
    },
    actions: {
        async getSignatureUrl({ state }, file) {
            let url
            if (/download\.(cloud|beta)\.custouch\.com/.test(file)) {
                url = await axios(file + '?view=2')
            } else {
                url = file
            }
            return url
        },
        async getJson({ state, dispatch }, file) {
            const fileName = file.slice(file.lastIndexOf('/') + 1)
            if (!state.fileData[fileName]) {
                const { VUE_APP_DATA, VUE_APP_DATA_BASE } = process.env
                let url
                if (VUE_APP_DATA_BASE) {
                    url = VUE_APP_DATA_BASE + file
                } else {
                    url = file
                }
                if (/^(https?:)?\/\//.test(url)) {
                    url = await dispatch('getSignatureUrl', url)
                } else {
                    if (VUE_APP_DATA) {
                        url = VUE_APP_DATA
                    } else {
                        throw Error('文件格式错误或缺少必要的文件配置')
                    }
                    url += file
                    if (!url.endsWith('.json')) {
                        url += 'json'
                    }
                }
                const data = await getJson(url)
                if (data) {
                    state.fileData[fileName] = data
                }
            }
            return JSON.parse(JSON.stringify(state.fileData[fileName]))
        }
    }
})