var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media"},[_c('blm-header'),_c('TopSwiper'),(_vm.productData.data)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"slidebox slidebox1"}),_c('div',{staticClass:"slidebox slidebox3"}),_c('blm-bread',{attrs:{"breadList":_vm.breadList}}),_c('div',{ref:"slide",staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_c('div',{staticClass:"title btitle"},[_vm._v(_vm._s(_vm.productData.name))]),_c('p',{staticClass:"intro pintro introContent",staticStyle:{"width":"902px"},domProps:{"innerHTML":_vm._s(_vm.productData.serviceContent)}}),_vm._l((_vm.productData.data),function(item,index){return _c('div',{key:index,class:[
                'type',
                'clearfix',
                { type1: index === 0 },
                { type2: index === 1 },
                { type3: index === 2 }
            ]},[_c('div',{ref:"type",refInFor:true,staticClass:"img"},[_c('img',{staticClass:"img1",attrs:{"src":item.listImg,"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"subtitle intro"},[_c('div',{staticClass:"introContent"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('a',{staticClass:"more textRight",on:{"mouseover":function($event){item.hidemore = true},"mouseout":function($event){item.hidemore = false},"click":function($event){return _vm.jump(item.title)}}},[_c('span',[_vm._v("了解更多")]),_c('img',{class:{ show: item.hidemore },attrs:{"src":require("../assets/images/arrow.png"),"alt":""}})])])])})],2):_vm._e(),_c('blm-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }