<template>
    <div class="product">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/19608/产品和服务banner.png"
                alt=""
            />
            <p>
                BLM Digital <br />
                与您共创数字化世界
            </p>
        </div>
        <div class="container">
            <blm-bread :breadList="breadList"></blm-bread>
            <div class="slide"></div>
            <div class="slide1"></div>
            <div class="slide2"></div>
            <div class="slide3"></div>
            <a-back-top>
                <img src="../assets/images/top.png" alt="" class="slide4" />
            </a-back-top>
            <div class="title btitle" ref="product">产品和服务</div>
            <p class="intro">
                截止目前公司已累计提供了超过2000万平方米建筑面积的BIM全过程咨询服务，并且通过自主研发的智慧运维管理平台，已在部分园区、办公楼、社区、综合管廊等领域得到了成功应用。以“BIM赋能建筑数字化”为核心，打通建筑行业上下游产业链，围绕规划、设计、施工、运维各阶段的建筑全生命周期管理，提供专业化咨询技术服务，解决传统建筑行业数字化转型问题。
            </p>
            <div class="main">
                <div class="item1 clearfix">
                    <div class="title" ref="item1">BLM服务</div>
                    <div class="content">
                        <div
                            class="down"
                            v-for="(item, index) in service"
                            :key="index"
                        >
                            <div
                                v-if="item.children"
                                :class="{
                                    pull: true,
                                    [item.show && item.children
                                        ? 'active'
                                        : '']: true
                                }"
                                @click="item.show = !item.show"
                            >
                                <img
                                    :class="[item.show ? '' : 'bottom']"
                                    src="../assets/images/down.png"
                                    alt=""
                                />
                                {{ item.title }}
                            </div>
                            <div
                                class="pull"
                                v-else
                                @click="goLink(item.title, 'serviceList')"
                            >
                                <img
                                    v-if="!item.children"
                                    src="../assets/images/arrow.png"
                                    alt=""
                                />
                                {{ item.title }}
                            </div>
                            <div class="div" v-if="item.show">
                                <div
                                    class="hide"
                                    v-for="(value, key) in item.children"
                                    :key="key"
                                >
                                    <div
                                        class="ctitle"
                                        @click="
                                            goLink(value.ctitle, 'serviceList')
                                        "
                                    >
                                        {{ value.ctitle }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img
                        class="levelimg"
                        src="https://oss.cloud.custouch.com/res/18983/官网-产品和服务-BLM服务900x580.jpg"
                        alt=""
                    />
                </div>
                <div class="item2 clearfix">
                    <div class="title" ref="item2">BLM产品</div>
                    <img
                        class="levelimg"
                        src="https://oss.cloud.custouch.com/res/18966/官网-产品和服务-BLM产品900x580.jpg"
                        alt=""
                    />
                    <div class="content ">
                        <div
                            class="pull"
                            v-for="(item, index) in product"
                            :key="index"
                            @click="goLink(item.level2, 'productList')"
                        >
                            <img src="../assets/images/arrow.png" alt="" />
                            {{ item.level2 }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
export default {
    data() {
        return {
            service: [],
            product: [],
            scroll1: true,
            scroll2: true,
            scroll3: true
        }
    },
    created() {
        this.getList()
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)
        this.$nextTick(() => {
            this.animotion()
        })
    },
    methods: {
        async getList() {
            console.log(this.$root.path[this.$route.meta.title])
            const list = await this.$store.dispatch(
                'getJson',
                this.$root.path[this.$route.meta.title]
            )
            const productData = list.filter(item => item.level1 === 'BLM产品')

            let map = new Map()
            for (let item of productData) {
                map.set(item.level2, item)
            }
            this.product = [...map.values()]

            const service = Array.from(
                new Set(
                    list
                        .filter(item => item.level1 === 'BLM服务')
                        .map(item => item.level2)
                )
            )
            service.map(item => {
                const name = Array.from(
                    new Set(
                        list.filter(ele => ele.level2 === item).map(e => e.name)
                    )
                )
                if (name.length === 1 && name[0] === item) {
                    this.service.push({
                        title: item
                    })
                } else {
                    const children = name.map(ele => {
                        return {
                            ctitle: ele
                        }
                    })
                    this.service.push({
                        title: item,
                        show: false,
                        children
                    })
                }
            })
        },
        detectScroll() {
            this.animotion()
        },
        animotion() {
            const item1 = this.$refs.item1.getBoundingClientRect().top
            const product = this.$refs.product.getBoundingClientRect().top
            const item2 = this.$refs.item2.getBoundingClientRect().top
            const item1Target = '.item1 .title, .item1 .pull, .item1 .levelimg'
            const item2Target = '.item2 .levelimg, .item2 .title, .item2 .pull'
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            if (product < clientH && this.scroll1) {
                this.scroll1 = false
                anime({
                    targets: '.slide', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.showAnime(' .slide1, .btitle, .intro')
            }
            if (item1 < clientH && this.scroll2) {
                this.scroll2 = false
                anime({
                    targets: '.slide2', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.showAnime(item1Target)
            }
            if (item2 < clientH && this.scroll3) {
                this.scroll3 = false
                anime({
                    targets: '.slide3', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.showAnime(item2Target)
            }
        },
        showAnime(target) {
            anime({
                targets: target, // 需要动画的元素
                opacity: [0, 1],
                translateY: [300, 0],
                duration: 700, // 动画的时长
                easing: 'easeInSine', //运动曲线
                delay: anime.stagger(200, { start: 100 })
            })
        },
        goLink(t, n) {
            if (t == '数字化建筑资产管理平台') {
                this.$router.push({
                    name: 'managePlatform'
                })
                console.log(t)
                return
            }
            this.$router.push({
                name: n,
                query: {
                    title: t
                }
            })
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        },
        banner() {
            return
        }
    }
}
</script>

<style lang="less" scoped>
.product {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 26%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.container {
    padding-bottom: 166px;
    position: relative;
    width: 930px;
    margin: 0 auto;
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .intro {
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
        margin-top: 40px;
        width: 900px;
    }
    .slide {
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 170px;
        left: -51px;
        width: 1006px;
        height: 144px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .slide2 {
        position: absolute;
        top: 550px;
        right: 0;
        width: 449px;
        height: 310px;
        background-color: #f9f9f9;
        z-index: -1;
    }
    .slide3 {
        position: absolute;
        top: 1126px;
        left: 0;
        width: 449px;
        height: 310px;
        background-color: #f9f9f9;
        z-index: -1;
    }
    /deep/ .ant-back-top {
        bottom: 270px;
        right: 7%;
        .slide4 {
            width: 68px;
        }
    }
}
.item1,
.item2 {
    margin-top: 104px;
    .title {
        text-align: center;
        margin-bottom: 20px;
    }
    .content {
        float: left;
        margin-right: 122px;
        .pull {
            cursor: pointer;
            width: 340px;
            height: 46px;
            line-height: 46px;
            font-size: 20px;
            // font-weight: bold;
            font-family: 'OPPOSM';
            margin: 10px 0 10px 0;
        }
        img {
            width: 21px;
            height: 21px;
            margin: 0 15px 5px 13px;
            vertical-align: middle;
            transform: rotate(0deg);
            transition: all 0.5s;
        }
        .bottom {
            transform: rotate(-90deg);
        }
        .active {
            box-shadow: 0 0 4px 0 #d0d0d0;
            color: #2486cb;
            font-family: 'OPPOSB';
        }
        .ctitle {
            cursor: pointer;
            font-size: 18px;
            padding-left: 52px;
            line-height: 40px;
            color: #4c4c4c;
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }
    .levelimg {
        width: 449px;
        margin-top: 20px;
    }
}
.item1 {
    height: 500px;
}
.item2 {
    margin-top: 100px;
    .levelimg {
        margin-left: 72px;
        margin-right: 68px;
        float: left;
    }
    .content {
        margin: 0;
        margin-top: 70px;
    }
}
</style>
