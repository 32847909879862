<template>
    <div class="strategy">
        <blm-header></blm-header>
        <div class="banner">
            <img
                src="https://oss.cloud.custouch.com/res/19609/公司战略banner.png"
                alt=""
            />
            <p>
                BLM Digital <br />
                与您共创数字化世界
            </p>
        </div>
        <div class="container">
            <div class="slide"></div>
            <div class="slide1"></div>
            <blm-bread :breadList="breadList"></blm-bread>
            <a-back-top :visibilityHeight="1000">
                <img src="../assets/images/top.png" alt="" class="slide3" />
            </a-back-top>
            <div class="title" ref="company">公司战略</div>
            <p class="intro">
                公司致力于建筑数字化的专业服务商，提供BLM建筑全生命周期的管理，结合工程建设各阶段应用服务，并运用新一代信息技术（AI、5G、云计算等）实现建筑数字化，助力建筑资产保值增值，为健康建筑保驾护航。
            </p>
        </div>
        <div
            :class="[
                'desc',
                'cleatfix',
                { desc1: index === 0 },
                { desc2: index === 1 },
                { desc3: index === 2 }
            ]"
            v-for="(item, index) in list"
            :key="index"
        >
            <div class="title" ref="desc">{{ item.name }}</div>
            <div class="subtitle">{{ item.subTitle }}</div>
            <div class="contain clearfix">
                <div class="word">
                    <div class="content" v-html="item.content"></div>
                </div>
                <img :src="item.banner" alt="" />
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
// import {list} from '../assets/data/strategy'
export default {
    data() {
        return {
            // list:list,
            list: [],
            scroll: true,
            desc1Scroll: true,
            desc2Scroll: true,
            desc3Scroll: true
        }
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        }
    },
    created() {
        this.getList()
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)
        this.$nextTick(() => {
            this.animotion()
        })
    },
    methods: {
        async getList() {
            this.list = await this.$store.dispatch(
                'getJson',
                this.$root.path['公司战略']
            )
        },
        detectScroll() {
            this.animotion()
        },
        animotion() {
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            const company = this.$refs.company.getBoundingClientRect().top
            const desc1 =
                this.$refs.desc &&
                this.$refs.desc[0].getBoundingClientRect().top
            const desc2 =
                this.$refs.desc &&
                this.$refs.desc[1].getBoundingClientRect().top
            const desc3 =
                this.$refs.desc &&
                this.$refs.desc[2].getBoundingClientRect().top
            const targets1 = `.desc1 .contain img ,.desc1 img, .desc1 .title, .desc1 .subtitle, .desc1 p`
            const targets2 = `.desc2 .contain img ,.desc2 img, .desc2 .title, .desc2 .subtitle, .desc2 p`
            const targets3 = `.desc3 .contain img ,.desc3 img, .desc3 .title, .desc3 .subtitle, .desc3 p`
            if (company < clientH && this.scroll) {
                this.scroll = false
                anime({
                    targets: '.slide', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.showAnime('.container .title, .slide1, .intro')
            }
            if (desc1 < clientH && this.desc1Scroll) {
                this.showAnime(targets1)
                this.desc1Scroll = false
            }
            if (desc2 < clientH && this.desc2Scroll) {
                this.showAnime(targets2)
                this.desc2Scroll = false
            }
            if (desc3 < clientH && this.desc3Scroll) {
                this.showAnime(targets3)
                this.desc3Scroll = false
            }
        },
        showAnime(target) {
            anime({
                targets: target, // 需要动画的元素
                opacity: [0, 1],
                translateY: [300, 0],
                duration: 700, // 动画的时长
                easing: 'easeInSine', //运动曲线
                delay: anime.stagger(200, { start: 0 })
            })
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    }
}
</script>

<style lang="less" scoped>
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.strategy {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 16%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }
    .slide {
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 170px;
        left: -51px;
        width: 1006px;
        height: 110px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
    }
    .intro {
        font-size: 16px;
        line-height: 32px;
        text-align: justify;
        color: rgba(76, 76, 76, 1);
        margin-top: 40px;
    }
    .container {
        position: relative;
        width: 900px;
        margin: 0 auto;
        /deep/ .ant-back-top {
            bottom: 270px;
            right: 7%;
            .slide3 {
                width: 68px;
            }
        }
    }
    .desc {
        padding-top: 105px;
        width: 1006px;
        height: 100%;
        margin: 0 auto;
        .title,
        .subtitle {
            margin-left: 55px;
        }
        &:last-child {
            margin-bottom: 100px;
        }
        .contain {
            width: 1006px;
            position: relative;
            background-color: #f7f7f7;
            height: 100%;
            padding: 24px 55px 8px 55px;
        }
        .subtitle {
            font-size: 20px;
            line-height: 64px;
            color: #000000;
            margin-bottom: 24px;
        }
        .word {
            float: left;
            width: 500px;
            /deep/ p {
                font-size: 16px;
                line-height: 32px;
                color: #4c4c4c;
                text-align: justify;
            }
        }
        img {
            top: -158px;
            right: 55px;
            position: absolute;
            margin-top: 18px;
            width: 360px;
        }
    }
    .footer {
        margin-top: 96px;
    }
}
</style>
