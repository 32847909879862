<template>
    <div class="media">
        <blm-header></blm-header>
        <TopSwiper></TopSwiper>
        <div class="container" v-if="productData.data">
            <blm-bread :breadList="breadList" :query="query"></blm-bread>

            <div ref="slide" class="slide"></div>
            <!-- <div class="slide1"></div> -->

            <div class="title btitle">{{ query }}</div>
            <p class="intro pintro">{{ productData.synopsis }}</p>
            <img :src="productData.bannerImg" alt="" style="width:100%" />
            <div class="main">
                <div v-for="(item, index) in productData.data" :key="index">
                    <div class="mainItemTitle">
                        <div class="titleIndex">{{ index + 1 }}</div>
                        <div class="titleContent">{{ item.title }}</div>
                    </div>
                    <div v-html="item.content"></div>
                    <div v-if="item.data">
                        <div
                            class="item2"
                            v-for="(item2, index) in item.data"
                            :key="index"
                        >
                            <div class="item2Title">
                                {{ item2.title }}
                            </div>
                            <div
                                class="item2Content"
                                v-html="item2.content"
                            ></div>
                            <img :src="item2.img" v-if="item2.img" alt="" />
                            <img :src="item2.img2" v-if="item2.img2" alt="" />
                            <p class="imgTitle">{{ item2.imgTitle }}</p>
                        </div>
                    </div>
                </div>
                <div
                    class="footerContent"
                    v-html="productData.footContent"
                ></div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
import getParentId from '@/service/recursion.js'
import TopSwiper from './components/TopSwiper.vue'
export default {
    data() {
        return {
            // type1: 0,

            scroll1: true,
            productData: []
        }
    },
    components: {
        TopSwiper
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        },
        query() {
            return this.$route.query.title
        }
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)
        this.getData()
    },
    methods: {
        async getData() {
            let list = await this.$store.dispatch(
                'getJson',
                this.$root.path['产品和服务']
            )
            // this.productData= list.find((item)=>{
            //     if(item.name == '成功案例'){
            //         return item
            //     }
            // })
            const data = list.find(item => item.name == '成功案例')
            this.productData = data.data.find(item => item.title == this.query)
            console.log(this.productData)
            this.$nextTick(() => {
                this.animotion()
            })
        },

        detectScroll() {
            this.animotion()
        },
        animotion() {
            const slide = this.$refs.slide.getBoundingClientRect().top
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            if (slide < clientH && this.scroll1) {
                this.scroll1 = false
                anime({
                    targets: '.slide', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.animeted(' .slide1, .btitle, .pintro')
            }
        },
        animeted(targets) {
            anime({
                targets, // 需要动画的元素
                opacity: [0, 1],
                translateY: [300, 0],
                duration: 700, // 动画的时长
                easing: 'easeInSine', //运动曲线
                delay: anime.stagger(200, { start: 200 })
            })
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    }
}
</script>

<style lang="less" scoped>
.media {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 74%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }
}

.container {
    position: relative;
    width: 900px;
    margin: 0 auto;
    font-family: OPPOSans-M;
    .slide {
        opacity: 0;
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 170px;
        left: -51px;
        width: 1006px;
        height: 77px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .title {
        opacity: 0;
        font-size: 32px;
        line-height: 32px;
        vertical-align: top;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
        // margin-left: 30px;
    }
    .pintro {
        opacity: 0;
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
        margin-top: 60px;
        background-color: #f4fafd;
        padding: 24px 30px;
    }
    .main {
        margin-bottom: 90px;
        .mainItemTitle {
            position: relative;
            text-align: center;
            margin-bottom: 30px;
            margin-top: 60px;
            .titleIndex {
                width: 55px;
                height: 63px;
                background-color: #e9f5fc;
                font-weight: 550;
                font-size: 40px;
            }
            .titleContent {
                position: absolute;
                top: 13px;
                left: 36px;
                // width: 354px;
                padding: 0 19px 0 31px;
                height: 63px;
                background: rgba(145, 205, 240, 0.1);
                font-size: 20px;
                font-weight: 550;
                line-height: 63px;
            }
        }
        .item2 {
            img {
                width: 100%;
                margin-top: 20px;
            }
            .imgTitle {
                font-family: OPPOSans-R;
                font-weight: R;
                font-size: 12px;
                color: #4c4c4c;
                letter-spacing: 0;
                text-align: center;
                line-height: 32px;
                margin-top: 20px;
            }
        }
        .item2Title {
            margin: 20px 0;
            font-family: OPPOSans-M;
            font-weight: 550;
            font-size: 16px;
            color: #4c4c4c;
            letter-spacing: 0;
            text-align: justify;
            line-height: 32px;
        }
        .item2Content {
            line-height: 32px;
        }
    }
    .footerContent {
        font-family: OPPOSans-B;
        font-weight: B;
        font-size: 16px;
        color: #4c4c4c;
        letter-spacing: 0;
        text-align: justify;
        line-height: 32px;
    }
}
</style>
