<template>
    <div class="media">
        <blm-header></blm-header>
        <TopSwiper></TopSwiper>
        <div class="container" v-if="productData.data">
            <div class="slidebox slidebox1"></div>
            <!-- <div class="slidebox slidebox2"></div> -->
            <div class="slidebox slidebox3"></div>
            <blm-bread :breadList="breadList"></blm-bread>
            <div ref="slide" class="slide"></div>
            <div class="slide1"></div>
            <div class="title btitle">{{ productData.name }}</div>
            <p
                class="intro pintro introContent"
                style="width:902px;"
                v-html="productData.serviceContent"
            ></p>
            <div
                :class="[
                    'type',
                    'clearfix',
                    { type1: index === 0 },
                    { type2: index === 1 },
                    { type3: index === 2 }
                ]"
                v-for="(item, index) in productData.data"
                :key="index"
            >
                <div class="img" ref="type">
                    <img :src="item.listImg" alt="" class="img1" />
                </div>
                <div class="title">{{ item.name }}</div>
                <div class="right">
                    <div class="subtitle intro">
                        <div class="introContent">
                            {{ item.title }}
                        </div>
                    </div>
                    <!-- <div class="blueRectangle subtitle"></div> -->
                    <a
                        class="more textRight"
                        @mouseover="item.hidemore = true"
                        @mouseout="item.hidemore = false"
                        @click="jump(item.title)"
                    >
                        <span>了解更多</span>
                        <img
                            src="../assets/images/arrow.png"
                            alt=""
                            :class="{ show: item.hidemore }"
                        />
                    </a>
                </div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
import getParentId from '@/service/recursion.js'
import TopSwiper from './components/TopSwiper.vue'
export default {
    data() {
        return {
            // type1: 0,
            showSlide: true,
            showType1: true,
            showType2: true,
            showType3: true,
            scroll1: true,
            productData: []
        }
    },
    components: {
        TopSwiper
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList || []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.detectScroll)

        this.getData()
    },
    methods: {
        async getData() {
            let list = await this.$store.dispatch(
                'getJson',
                this.$root.path['产品和服务']
            )
            this.productData = list.find(item => {
                if (item.name == '成功案例') {
                    return item
                }
            })
            this.$nextTick(() => {
                this.animotion()
            })
        },

        jump(i) {
            console.log(i)
            this.$router.push({
                name: 'CaseDetail',
                query: {
                    title: i
                }
            })
        },
        detectScroll() {
            this.animotion()
        },
        animotion() {
            const slide = this.$refs.slide.getBoundingClientRect().top
            const type1 = this.$refs.type[0].getBoundingClientRect().top
            const type2 = this.$refs.type[1].getBoundingClientRect().top
            // const type3 = this.$refs.type[2].getBoundingClientRect().top
            const target1 = [
                '.type1 .img',
                '.type1 .title',
                '.slidebox3',
                '.type1 .subtitle',
                '.type1 .more'
            ]
            const target2 = [
                '.type2 .img',
                '.type2 .title',
                '.slidebox1',
                '.type2 .subtitle',
                '.type2 .more'
            ]
            const target3 = [
                '.type3 .img',
                '.type3 .title',
                '.slidebox2',
                '.type3 .subtitle',
                '.type3 .more'
            ]
            const clientH =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            if (slide < clientH && this.scroll1) {
                this.scroll1 = false
                anime({
                    targets: '.slide', // 需要动画的元素
                    opacity: [0, 1],
                    translateX: [-300, 0],
                    duration: 500, // 动画的时长
                    easing: 'easeInSine' //运动曲线
                })
                this.animeted(' .slide1, .btitle, .pintro')
            }

            if (type1 < clientH && this.showType1) {
                this.showType1 = false
                this.animeted(target1)
            }
            if (type2 < clientH && this.showType2) {
                this.showType2 = false
                this.animeted(target2)
            }
            // if (type3 < clientH && this.showType3) {
            //   this.showType3 = false
            //   this.animeted(target3)
            // }
        },
        animeted(targets) {
            anime({
                targets, // 需要动画的元素
                opacity: [0, 1],
                translateY: [300, 0],
                duration: 700, // 动画的时长
                easing: 'easeInSine', //运动曲线
                delay: anime.stagger(200, { start: 200 })
            })
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    }
}
</script>

<style lang="less" scoped>
.media {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 74%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            // font-weight: bold;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }

    .slidebox {
        // opacity: 0;
        position: absolute;
        width: 1006px;
        height: 208px;
        background-color: #f7f7f7;
        left: -55px;
        z-index: -1;
    }
    .slidebox1 {
        top: 799px;
    }
    // .slidebox2 {
    //   top: 971px;
    // }
    .slidebox3 {
        top: 400px;
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.container {
    position: relative;
    width: 900px;
    margin: 0 auto;
    .slide {
        opacity: 0;
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 170px;
        left: -51px;
        width: 1006px;
        height: 110px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .title {
        opacity: 0;
        font-size: 32px;
        line-height: 32px;
        vertical-align: top;
        text-align: left;
        // font-weight: 500;
        font-family: 'OPPOSM';
        color: #000;
        // margin-left: 30px;
    }
    .intro {
        opacity: 0;
        font-size: 16px;
        line-height: 32px;
        color: #4c4c4c;
        margin-top: 70px;
    }

    .more {
        opacity: 0;
        text-align: right;
        display: inline-block;
        font-size: 16px;
        line-height: 21px;
        font-family: 'OPPOSM';
        color: #2486cb;
        margin-top: 74px;
        img {
            transition: all 0.8s ease;
            width: 22px;
            opacity: 0;
            margin-left: 10px;
            transform: translateX(-10px);
            &.show {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
    p {
        margin-top: 40px;
        margin-bottom: 24px;
    }
    .type {
        margin-top: 83px;
        &:last-child {
            margin-bottom: 96px;
        }
        .right {
            float: left;
            // padding-top: 55px;
            .introContent {
                // width: 512px;
            }
        }
        .img {
            opacity: 0;
            float: left;
            width: 594px;
            height: 315px;
            overflow: hidden;
            // margin-right: 30px;
        }

        .img1 {
            width: 100%;
            height: 100%;

            transition: all 1s ease;
            &:hover {
                transform: scale(2);
            }
        }
    }
    .type:nth-child(2n) {
        .right {
            float: left;
            // margin-left: 32px;
        }
        .img {
            opacity: 0;
            float: right;
            overflow: hidden;
            // margin-left: 30px;
        }
    }
    .type:nth-child(2n + 1) {
        .img {
            margin-right: 50px;
        }
        .title {
            // margin-right: 10px;
        }
        .right {
            margin-left: 102px;
            // text-align: right;
            span {
                display: block;
                width: 144px;
            }
        }
        // .more{
        //   margin-left: 202px;
        // }
    }
}
.blueRectangle {
    width: 64px;
    height: 12px;
    background: #91cdf0;
}
</style>
