var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servicelist"},[_c('blm-header'),_vm._m(0),_c('div',{staticClass:"container"},[_c('blm-bread',{attrs:{"breadList":_vm.breadList,"query":_vm.query}}),_c('a-back-top',{attrs:{"visibilityHeight":1000}},[_c('img',{staticClass:"slide3",attrs:{"src":require("../assets/images/top.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.query))]),(_vm.show)?_c('div',{staticClass:"intro"},[_vm._v(" 包括空调、通风、采暖系统；供电系统；给排水系统；消防系统；弱电系统；垂直运输系统 ")]):_vm._e(),_c('div',{staticClass:"main clearfix"},[_c('div',{staticClass:"left clearfix"},[_c('div',{staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:{
                        btn: true,
                        [_vm.activeIndex === index ? 'active' : '']: true
                    },on:{"click":function($event){_vm.activeIndex = index}}},[_c('div',{staticClass:"ctitle"},[_vm._v(_vm._s(item.service))]),_c('div',{class:{
                            circle: true,
                            [_vm.activeIndex === index ? 'active' : '']: true
                        }})])})],2),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"right"},[(index === _vm.activeIndex)?_c('div',[(item.serviceContent && _vm.activeIndex === index)?_c('div',_vm._l((item.serviceContent),function(value,k){return _c('p',{key:k,domProps:{"innerHTML":_vm._s(value)}},[(item.serviceLink)?_c('a',{attrs:{"href":item.serviceLink}},[_vm._v("了解更多")]):_vm._e()])}),0):_vm._e(),(item.serviceImg)?_c('img',{attrs:{"src":item.serviceImg,"alt":""}}):_vm._e()]):_vm._e()])})],2)],1),_c('blm-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":"https://oss.cloud.custouch.com/res/19626/官网-产品和服务-BLM服务banner2880x954.jpg","alt":""}})])
}]

export { render, staticRenderFns }