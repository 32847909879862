<template>
    <div class="bread">
        <a-breadcrumb separator=">">
            <a-breadcrumb-item>
                <img
                    src="../../assets/images/home.png"
                    alt=""
                    @click="$router.push('/')"
                />
            </a-breadcrumb-item>
            <a-breadcrumb-item
                v-for="(item, index) in breadList"
                :key="index"
                @click.native="$router.push(item.path)"
            >
                {{ item.name }}
            </a-breadcrumb-item>
            <a-breadcrumb-item v-if="query">
                {{ query }}
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>
</template>

<script>
export default {
    props: {
        breadList: {
            required: true,
            type: Array
        },
        query: {
            type: String
        }
    }
}
</script>

<style lang="less" scoped>
.bread {
    img {
        margin-right: 16px;
        width: 26px;
        vertical-align: bottom;
    }
}
/deep/ .ant-breadcrumb {
    margin: 26px 0 65px 0;
    span {
        cursor: pointer;
        color: #000;
        font-size: 16px;
        // font-weight: 500;
        font-family: 'OPPOSR';
    }
    span:first-child {
        /deep/ .ant-breadcrumb-separator {
            display: none;
        }
    }
    span:last-child {
        /deep/ .ant-breadcrumb-link {
            color: #878787;
        }
    }
}
</style>
