<template>
    <div class="concact">
        <blm-header></blm-header>
        <div class="banner">
            <img :src="detail.banner" alt="" />
            <p v-html="detail.bannerText"></p>
        </div>
        <div class="contain">
            <div class="slide"></div>
            <div class="slide1"></div>
            <blm-bread :breadList="breadList"></blm-bread>
            <div class="title">联系我们</div>
            <div class="company clearfix">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <img class="" :src="item.img" alt="" />
                    <p class="intro">{{ item.company }}</p>
                    <p class="detail">{{ item.desc }}</p>
                </div>
            </div>
            <div class="title">联络方式</div>
            <div class="way">
                <p>
                    电话:<span>{{ detail.tel }}</span>
                    <img
                        src="../assets/images/copy.png"
                        alt=""
                        class="copy"
                        :data-clipboard-text="detail.tel"
                        @click="copy($event)"
                        data-category="click"
                        data-action="tel"
                        :data-label="detail.tel"
                    />
                    <br />
                    邮箱:
                    <a
                        :href="`mailto:${detail.mail}`"
                        @click="jump($event)"
                        data-category="click"
                        data-action="mail"
                        :data-label="detail.mail"
                        >{{ detail.mail }}</a
                    ><br />
                    微官网:
                    <a
                        target="_blank"
                        :href="detail.site"
                        @click="jump($event)"
                        data-category="click"
                        data-action="微官网"
                        :data-label="detail.site"
                        >{{ detail.site }}</a
                    ><br />
                    地址: <i>{{ detail.address }}</i>
                    <img
                        src="../assets/images/copy.png"
                        alt=""
                        class="copy"
                        :data-clipboard-text="detail.address"
                        @click="copy($event)"
                        data-category="click"
                        data-action="address"
                        :data-label="detail.address"
                    />
                </p>
                <div id="container"></div>
            </div>
        </div>
        <blm-footer></blm-footer>
    </div>
</template>

<script>
import anime from 'animejs'
import mixin from './mixins/mixin'
import Clipboard from 'clipboard'
export default {
    mixins: [mixin],
    data() {
        return {
            list: [],
            detail: {},
        }
    },
    computed: {
        breadList() {
            return this.$route.meta.breadList
        },
    },
    async created() {
        const data = await this.$store.dispatch(
            'getJson',
            this.$root.path['contact']
        )
        this.list = data.map((item) => {
            return {
                img: item.img,
                company: item.company,
                desc: item.desc,
            }
        })
        this.detail = data[0]
    },
    mounted() {
        var map = new BMapGL.Map('container')
        var point = new BMapGL.Point(121.598139, 31.251711)
        // 创建点坐标
        map.centerAndZoom(point, 18)
        var marker = new BMapGL.Marker(point) // 创建标注
        map.addOverlay(marker) // 将标注添加到地图中
        window.addEventListener('scroll', this.detectScroll)
        this.$nextTick(() => {
            anime({
                targets: '.slide', // 需要动画的元素
                opacity: [0, 1],
                translateX: [-200, 0],
                duration: 1000, // 动画的时长
                easing: 'easeInSine', //运动曲线
            })
        })
    },
    methods: {
        copy(ev) {
            let _this = this
            var clipboard = new Clipboard('.copy')
            clipboard.on('success', (e) => {
                const title = _this.$route.meta.title
                _this.track(ev, title)
                _this.$message.success('已复制')
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', (e) => {
                // 不支持复制
                alert('不支持复制')
                // 释放内存
                clipboard.destroy()
            })
        },
        jump(ev) {
            const title = this.$route.meta.title
            this.track(ev, title)
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.detectScroll)
    },
}
</script>

<style lang="less" scoped>
.concact {
    .banner {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 29%;
            z-index: 999;
            line-height: 68px;
            font-size: 38px;
            font-family: 'OPPOSB';
            color: #fff;
        }
    }
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.contain {
    padding-bottom: 166px;
    position: relative;
    width: 900px;
    margin: 0 auto;
    .slide {
        position: absolute;
        top: 84px;
        left: -268px;
        width: 244px;
        height: 110px;
        background-color: rgba(36, 134, 203, 0.2);
    }
    .slide1 {
        position: absolute;
        top: 172px;
        left: -51px;
        width: 1006px;
        height: 77px;
        background-color: rgba(145, 205, 240, 0.1);
        z-index: -1;
    }
    .title {
        font-size: 32px;
        line-height: 64px;
        text-align: left;
        font-family: 'OPPOSM';
        color: #000;
    }
}
.company {
    margin: 147px 0 54px 0;
    .item {
        float: left;
        width: 409px;
        &:first-child {
            margin-right: 82px;
        }
        img {
            width: 100%;
            height: 237px;
            object-fit: cover;
        }
        .intro {
            margin: 16px 0 6px 0;
            font-size: 20px;
            line-height: 40px;
            color: #000;
        }
        .detail {
            font-size: 16px;
            line-height: 32px;
            color: #4c4c4c;
            margin-bottom: 0;
        }
    }
}
.way {
    margin-top: 16px;
    p {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 45px;
        color: #4c4c4c;
        span {
            display: inline-block;
            margin-left: 16px;
            color: #1da2ff;
        }
        a {
            color: #1da2ff;
            margin-left: 16px;
        }
    }
    img {
        width: 16px;
        margin-left: 16px;
        margin-bottom: 5px;
        cursor: pointer;
    }
    i {
        margin-left: 16px;
        font-style: normal;
    }
}
#container {
    width: 900px;
    height: 314px;
    margin-bottom: 104px;
}
/deep/ .BMap_cpyCtrl {
    display: none;
}
/deep/ .anchorBL {
    img {
        display: none;
    }
}
</style>
