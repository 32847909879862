var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"strategy"},[_c('blm-header'),_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_c('blm-bread',{attrs:{"breadList":_vm.breadList}}),_c('a-back-top',{attrs:{"visibilityHeight":1000}},[_c('img',{staticClass:"slide3",attrs:{"src":require("../assets/images/top.png"),"alt":""}})]),_c('div',{ref:"company",staticClass:"title"},[_vm._v("公司战略")]),_c('p',{staticClass:"intro"},[_vm._v(" 公司致力于建筑数字化的专业服务商，提供BLM建筑全生命周期的管理，结合工程建设各阶段应用服务，并运用新一代信息技术（AI、5G、云计算等）实现建筑数字化，助力建筑资产保值增值，为健康建筑保驾护航。 ")])],1),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
            'desc',
            'cleatfix',
            { desc1: index === 0 },
            { desc2: index === 1 },
            { desc3: index === 2 }
        ]},[_c('div',{ref:"desc",refInFor:true,staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(item.subTitle))]),_c('div',{staticClass:"contain clearfix"},[_c('div',{staticClass:"word"},[_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(item.content)}})]),_c('img',{attrs:{"src":item.banner,"alt":""}})])])}),_c('blm-footer')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":"https://oss.cloud.custouch.com/res/19609/公司战略banner.png","alt":""}}),_c('p',[_vm._v(" BLM Digital "),_c('br'),_vm._v(" 与您共创数字化世界 ")])])
}]

export { render, staticRenderFns }