var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('blm-header'),_vm._m(0),_c('div',{staticClass:"container"},[_c('blm-bread',{attrs:{"breadList":_vm.breadList}}),_c('div',{staticClass:"slide"}),_c('div',{staticClass:"slide1"}),_c('div',{staticClass:"slide2"}),_c('div',{staticClass:"slide3"}),_c('a-back-top',[_c('img',{staticClass:"slide4",attrs:{"src":require("../assets/images/top.png"),"alt":""}})]),_c('div',{ref:"product",staticClass:"title btitle"},[_vm._v("产品和服务")]),_c('p',{staticClass:"intro"},[_vm._v(" 截止目前公司已累计提供了超过2000万平方米建筑面积的BIM全过程咨询服务，并且通过自主研发的智慧运维管理平台，已在部分园区、办公楼、社区、综合管廊等领域得到了成功应用。以“BIM赋能建筑数字化”为核心，打通建筑行业上下游产业链，围绕规划、设计、施工、运维各阶段的建筑全生命周期管理，提供专业化咨询技术服务，解决传统建筑行业数字化转型问题。 ")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"item1 clearfix"},[_c('div',{ref:"item1",staticClass:"title"},[_vm._v("BLM服务")]),_c('div',{staticClass:"content"},_vm._l((_vm.service),function(item,index){return _c('div',{key:index,staticClass:"down"},[(item.children)?_c('div',{class:{
                                pull: true,
                                [item.show && item.children
                                    ? 'active'
                                    : '']: true
                            },on:{"click":function($event){item.show = !item.show}}},[_c('img',{class:[item.show ? '' : 'bottom'],attrs:{"src":require("../assets/images/down.png"),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]):_c('div',{staticClass:"pull",on:{"click":function($event){return _vm.goLink(item.title, 'serviceList')}}},[(!item.children)?_c('img',{attrs:{"src":require("../assets/images/arrow.png"),"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")]),(item.show)?_c('div',{staticClass:"div"},_vm._l((item.children),function(value,key){return _c('div',{key:key,staticClass:"hide"},[_c('div',{staticClass:"ctitle",on:{"click":function($event){return _vm.goLink(value.ctitle, 'serviceList')}}},[_vm._v(" "+_vm._s(value.ctitle)+" ")])])}),0):_vm._e()])}),0),_c('img',{staticClass:"levelimg",attrs:{"src":"https://oss.cloud.custouch.com/res/18983/官网-产品和服务-BLM服务900x580.jpg","alt":""}})]),_c('div',{staticClass:"item2 clearfix"},[_c('div',{ref:"item2",staticClass:"title"},[_vm._v("BLM产品")]),_c('img',{staticClass:"levelimg",attrs:{"src":"https://oss.cloud.custouch.com/res/18966/官网-产品和服务-BLM产品900x580.jpg","alt":""}}),_c('div',{staticClass:"content"},_vm._l((_vm.product),function(item,index){return _c('div',{key:index,staticClass:"pull",on:{"click":function($event){return _vm.goLink(item.level2, 'productList')}}},[_c('img',{attrs:{"src":require("../assets/images/arrow.png"),"alt":""}}),_vm._v(" "+_vm._s(item.level2)+" ")])}),0)])])],1),_c('blm-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":"https://oss.cloud.custouch.com/res/19608/产品和服务banner.png","alt":""}}),_c('p',[_vm._v(" BLM Digital "),_c('br'),_vm._v(" 与您共创数字化世界 ")])])
}]

export { render, staticRenderFns }