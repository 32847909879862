/* eslint-disable*/
;(function(i, s, o, g, r, a, m) {
    i['CustouchWechatObject'] = r
    ;(i[r] =
        i[r] ||
        function() {
            ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', process.env.VUE_APP_WECHAT_KIT_SRC, 'cw')
;(function(i, s, o, g, r, a, m) {
    i['CustouchAnalyticsObject'] = r
    ;(i[r] =
        i[r] ||
        function() {
            ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', process.env.VUE_APP_CA_SRC, 'ca')
window.cw('appid', process.env.VUE_APP_APP_ID)
window.ca('create', 'UA-' + process.env.VUE_APP_SITE_ID, '', {
    storage: 'localStorage',
    transport: 'xhr'
})
window.ca('set', 'pid', process.env.VUE_APP_SITE_ID)
